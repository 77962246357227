import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import Papa from 'papaparse';
import Modal from "@mui/material/Modal";
import BasicTitle from "../components/title";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDropzone } from "react-dropzone";
import Loader from "./loader";

import { DataGrid } from "@mui/x-data-grid";

import Api from "../libs/api";
import Storage from "../libs/storage";
import Helper from "../libs/helper";
import { useImpersonation } from '../global/impersonation';
import { DownloadAddUsersExample, DownloadAddUsers } from "../utils/downloadCsv";

const ModalCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 688px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgb(144, 144, 144);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalView = styled.div`
  margin-bottom: 16px;
`;

const ModalLineView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: right;
  justify-content: flex-end;
`;

const Clear = styled.div`
  clear: both;
`;

export default function AddUser({
  type,
  title,
  open,
  close,
  banks,
  categories,
  projects,
  getUsers
}) {
  let api = Api();
  let storage = Storage();
  let helper = Helper();
  const { impersonateCompanyId } = useImpersonation();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [bank, setBank] = useState();
  const [hiring, setHiring] = useState(null);
  const [email, setEmail] = useState('');
  const [agency, setAgency] = useState(null);
  const [profile, setProfile] = useState(null);
  const [account, setAccount] = useState(null);
  const [cpf, setCpf] = useState(null);
  const [pixType, setPixType] = useState(null);
  const [pixKey, setPixKey] = useState(null);

  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([
    {
      id: 0,
      name: "---",
      email: "---",
      profile: "---",
    },
  ]);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Nome", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "profile", headerName: "Nível", width: 300 },
  ];

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [inputValueProject, setInputValueProject] = useState(''); 

  const activeProjects = projects.filter((project) => !project.disabled);
  
  const getProjectName = (id) => {
    const project = activeProjects.find((project) => project.id === id);
    return project ? project.name : '';
  };

  const handleInputChangeProject = (event, newInputValue) => {
    setInputValueProject(newInputValue);
  };

  const handleChangeProjects = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === 'allProjects') {
      const allProjects = activeProjects.map((project) => project.id);
      setSelectedProjects(allProjects);
    } else {
      setSelectedProjects(newValue.map((project) => project.id));
    }
  };

  const handleRemoveProject = (projectToRemove) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.filter((project) => project !== projectToRemove.id)
    );
  };

  const selectedProjectObjects = activeProjects.filter((project) =>
    selectedProjects.includes(project.id)
  );

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [inputValueCategory, setInputValueCategory] = useState('');

  const activeCategories = categories.filter((category) => !category.disabled);

  const getCategoryName = (id) => {
    const category = activeCategories.find((category) => category.id === id);
    return category ? category.name : '';
  };

  const handleInputChangeCategory = (event, newInputValue) => {
    setInputValueCategory(newInputValue);
  };

  const handleChangeCategories = (event, newValue) => {
    if (newValue && newValue[newValue.length - 1]?.id === 'allCategories') {
      const allCategories = activeCategories.map((category) => category.id);
      setSelectedCategories(allCategories);
    } else {
      setSelectedCategories(newValue.map((category) => category.id));
    }
  };

  const handleRemoveCategory = (categoryToRemove) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.filter((category) => category !== categoryToRemove.id)
    );
  };

  const selectedCategoryObjects = activeCategories.filter((categorie) =>
    selectedCategories.includes(categorie.id)
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [nextLoading, setNextLoading] = useState(false);

  const handleNextStep = async () => {
    if (step === 1) {
      setLoading(true);
      const data = await storage.getItem("data");

      const hiddenCategories = selectedCategories.length > 0
        ? categories.filter((categorie) => selectedCategories.includes(categorie.id)).map((categorie) => categorie.id)
        : selectedCategories

      const hiddenProjects = selectedProjects.length > 0
        ? projects.filter((project) => selectedProjects.includes(project.id)).map((project) => project.id)
        : selectedProjects

      await api.addUsers(
        data.token,
        users,
        hiddenCategories,
        hiddenProjects,
        impersonateCompanyId
      );
      setLoading(false);
      reset();
      close();
      getUsers("all", "all")
    } else {
      
      setNextLoading(true);
      const data = await storage.getItem("data");
      const emailCheck = await api.checkEmail(data.token, email)

      if (emailCheck.data.checkEmail) {
        setErrorMessage("Esse email já está cadastrado.");
        setNextLoading(false);
        return;
      }
      setStep(step + 1);
      setNextLoading(false);
    }
  };

  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  // const pix_type = {
  //   cpf: 1,
  //   cnpj: 1,
  //   email: 2,
  //   telefone: 3,
  //   chave: 4,
  // };

  const _user_type = ["Usuário", "Administrador", "Proprietário"];
  const [fileFormatOk, setFileFormatOk] = useState(false);
  const [fileName, setFileName] = useState('');

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file && file.name.endsWith(".csv")) {
        setFileFormatOk(true);
      } else {
        setFileFormatOk(false);
      }
      setFileName(file.name);
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
        const _users = [];
        const text = e.target.result;
        Papa.parse(text,
          {
            header: false,
            complete: (results) => {
              for (const user of results.data) {
                const newUser = {
                  name: user[0],
                  email: user[1],
                  profile: 0,
                  hiring: null,
                  cpf:null,
                  bank: null,
                  agency: null,
                  account: null,
                  pixType: null,
                  pixKey: null,
                }
                _users.push(newUser)
              }
              setUsers(_users);
            }
          }
        )
      } 
      
      reader.readAsText(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const reset = () => {
    setStep(0);
    setName(null);
    setBank(null);
    setHiring(null);
    setEmail(null);
    setAgency(null);
    setProfile(null);
    setAccount(null);
    setCpf(null);
    setPixType(null);
    setPixKey(null);
    setUsers([]);
    setSelectedProjects([]);
    setSelectedCategories([]);
    setLoading(false);
  };

  useEffect(() => {
    if (users.length > 0) {
      let _rows = [];
      for (let index = 0; index < users.length; index++) {
        const _user = users[index];
        _rows.push({
          id: index + 1,
          name: _user.name,
          email: _user.email,
          profile: _user_type[_user.profile],
        });
      }
      setRows(_rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (name && email) {
      setUsers([
        {
          name,
          bank: bank ? bank : null,
          hiring,
          email,
          agency: null,
          profile: 0,
          account: null,
          cpf: null,
          pixType: pixType ? pixType : null,
          pixKey: null,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    bank,
    hiring,
    email,
    agency,
    profile,
    account,
    cpf,
    pixType,
    pixKey,
  ]);

  const handleDownloadExample = () => {
    DownloadAddUsersExample();
  };

  const handleDownloadAddUsers= () => {
    DownloadAddUsers();
  };

 useEffect(() => setFileFormatOk(false),[])

  return (
    <Modal
      open={open}
      onClose={() => {
        reset();
        close();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalCenter style={{ width: 1280, maxWidth: "50%" }}>
        <ModalClose
          src="/img/modal_close.svg"
          onClick={() => {
            reset();
            close();
            setErrorMessage("");
            setNextLoading(false);
          }}
        />
        {loading && <Loader inside={true} />}
        <BasicTitle title={title} margin={32} />
        <Clear />
        <ModalView>
          {step === 0 && (
            <>
              {type === "one" && (
                <>
                  <ModalLineView>
                    <TextField
                      id="outlined-basic-1"
                      label="Nome *"
                      value={name}
                      variant="outlined"
                      style={{ width: "100%", }}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </ModalLineView>
                  <ModalLineView>
                  <TextField
                      id="outlined-basic-2"
                      label="Email *"
                      value={email}
                      variant="outlined"
                      style={{ width: "100%" }}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      onChange={(event) => {
                        const cleanedEmail = removeAccents(event.target.value);
                        setEmail(cleanedEmail);

                        if (!helper.validEmail(cleanedEmail)) {
                          setErrorMessage("E-mail inválido");
                        } else {
                          setErrorMessage("");
                        }
                      }}
                    />
                  </ModalLineView>
                  <ModalLineView>
                    <FormControl
                      style={{
                        width: "100%",
                        
                      }}
                    >
                      <InputLabel>Perfil</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Perfil *"
                        value={0}
                        onChange={(event) => {
                          setProfile(event.target.value);
                        }}
                      >
                        <MenuItem key={0} value={0}>
                          {_user_type[0]}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </ModalLineView>
                </>
              )}
              {type === "many" && (
                <>
                  <ModalLineView>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%"}}>
                      <span style={{ marginBottom: 16, fontSize: 12, fontWeight: 500 }}>
                        USE O ARQUIVO ABAIXO COMO MODELO, EDITE-O, E REENVIE PARA CADASTRAR OS DADOS PESSOAIS DOS USUÁRIOS.
                        <br />
                        AO PREENCHER, SIGA AS ORIENTAÇÕES DO CABEÇALHO DA PLANILHA.
                      </span>
                      <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                          <img src="/img/download.png" alt="" width={24} height={24} style={{ marginRight: 8 }} />
                          <button
                            onClick={handleDownloadAddUsers}
                            style={{ fontWeight: 500, color: "#57C4C2", cursor: "pointer", border: "none", background: "none", fontSize: 14, textDecoration: "underline",  }}
                          >
                            CLIQUE AQUI E BAIXE A PLANILHA-MODELO
                          </button>
                          <img src="/img/ajuda.png" alt="" width={24} height={24} style={{ marginRight: 8, marginLeft: 32}} />
                          <button
                            onClick={handleDownloadExample}
                            style={{ fontWeight: 500, color: "#78737F", cursor: "pointer", border: "none", background: "none", fontSize: 14, textDecoration: "underline", }}
                          >
                            FICOU COM DÚVIDAS, VEJA ESTE EXEMPLO
                          </button>
                      </div>
                    </div>
                  </ModalLineView>
                  <div
                    style={{
                      width: "100%",
                      padding: '34px 50px',
                      background: isDragActive ? "#FAFAFA" : "#F6F6F6",
                      border: users.length > 0 ? "1px solid #909090" : "2px dashed #909090",
                      borderRadius: 8,
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div style={{ display: "flex", gap: 8, alignItems: "center", justifyContent: "center" }}>
                        <img src="/img/upload.svg" alt="ícone de upload" width={24} height={24} />
                        <p style={{ textAlign: "center", fontWeight:"500", fontSize: 12, color: '#78737F' }}>
                          SOLTE PARA ADICIONAR O ARQUIVO...
                        </p>
                      </div>
                    ) : (
                      users.length > 0 ? (
                        fileFormatOk ? (
                          <div style={{ display: "flex", gap: 8, alignItems: "center", justifyContent: "center" }}>
                            <img src="/img/check.svg" alt="ícone de Check" width={16} height={16} />
                            <p style={{ textAlign: "center", fontWeight:"600", fontSize: 12, textTransform: "uppercase" }}>
                              {fileName}
                            </p>
                          </div>
                        ) : (
                          <div style={{ display: "flex", flexDirection: "column", gap: 30, alignItems: "center" }}>
                            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                              <img src="/img/alert.svg" alt="ícone de alerta" width={16} height={16} />
                              <p style={{ textAlign: "center", fontWeight:"600", fontSize: 12, textTransform: "uppercase" }}>
                                {fileName}
                              </p>
                            </div>
                            <p style={{ textAlign: "center", fontWeight:"600", textTransform: "uppercase", color: '#DE6061', fontSize: 10 }}>
                              o arquivo está em um formato não suportado, converta para .csv e envie novamente
                            </p>
                          </div>
                        )
                      ) : (
                        <div style={{ display: "flex", flexDirection: "column", gap: 26, alignItems: "center" }}>
                          <p style={{ textAlign: "center", textTransform: "uppercase", fontWeight:"500", color: "#4B4455" }}>
                            após CONCLUIR O PREENCHIMENTO DA PLANILHA-MODELO,  arraste-a AQUI OU clique nessa área para enviá-la
                          </p>
                          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                            <img src="/img/atencao.svg" alt="ícone de atenção" width={16} height={16} />
                            <p style={{ textAlign: "center", textTransform: "uppercase", fontWeight:"600", fontSize: 12, color: "#4B4455" }}>
                              só serão aceitos arquivos no formato .csv
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {(users.length > 0 && fileFormatOk) && (
                    <DataGrid
                      style={{ marginTop: 24 }}
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                      }}
                      pageSizeOptions={[5, 10, 50, 100]}
                      disableRowSelectionOnClick
                    />
                  )}
                </>
              )}
            </>
          )}
          {step === 1 && (
            <>
              <ModalLineView>
                <span>
                  Selecione os centros de custos e categorias que deseja liberar
                  para {type === "one" && "o usuário."}
                  {type === "many" && "os usuários."} <br />
                  <strong>
                    Deixando os campos em branco, o usuário não será cadastrado em nenhuma categoria ou centro de custo.
                  </strong>
                </span>
              </ModalLineView>
              <ModalLineView>
                <FormControl style={{ width: "100%", marginRight: 24 }}>
                <Autocomplete
                    style={{
                      maxHeight: '250px',
                      overflowY: 'auto',
                      paddingTop: '5px'
                    }}
                    multiple
                    id="autocomplete-projects"
                    options={[
                      { id: "allProjects", name: "SELECIONAR TODOS" },
                      ...activeProjects,
                    ]}
                    value={selectedProjectObjects}
                    onChange={handleChangeProjects}
                    inputValue={inputValueProject}
                    onInputChange={handleInputChangeProject}
                    getOptionLabel={(project) => {
                      if (project.id === 'allProjects') {
                        return <span style={{ color: '#419392', fontWeight: 500 }}>{project.name}</span>;
                      } else {
                        return project.name;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Centro de Custo" variant="outlined" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={getProjectName(option.id)}
                          onDelete={() => handleRemoveProject(option)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    freeSolo
                    ListboxProps={{
                      style: {
                        overflowY: 'auto', 
                        maxHeight: "220px"
                      },
                    }}
                  />
                </FormControl>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    style={{
                      maxHeight: '250px',
                      overflowY: 'auto',
                      paddingTop: '5px'
                    }}
                    multiple
                    id="autocomplete-categories"
                    options={[
                      { id: "allCategories", name: "SELECIONAR TODOS" },
                      ...activeCategories,
                    ]}
                    value={selectedCategoryObjects}
                    onChange={handleChangeCategories}
                    inputValue={inputValueCategory}
                    onInputChange={handleInputChangeCategory}
                    getOptionLabel={(category) => {
                      if (category.id === 'allCategories') {
                        return <span style={{ color: '#419392', fontWeight: 500 }}>{category.name}</span>;
                      } else {
                        return category.name;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Categorias" variant="outlined" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={getCategoryName(option.id)}
                          onDelete={() => handleRemoveCategory(option)}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    freeSolo
                    ListboxProps={{
                      style: {
                        overflowY: 'auto', 
                        maxHeight: "220px"
                      },
                    }}
                  />
                </FormControl>
              </ModalLineView>
            </>
          )}
        </ModalView>
        <Clear />
        <ModalFooter>
          {(type === "many") ? (
            <Button
              style={{
                fontSize: 12,
                padding: "8px 40px",
                opacity: fileFormatOk && users?.length > 0 ? 1 : 0.4,
              }}
              variant={fileFormatOk && users?.length > 0 ? "contained" : "outlined"}
              color={fileFormatOk && users?.length > 0 ? "primary" : "secondary"}
              onClick={fileFormatOk && users.length > 0 ? handleNextStep : () => { }}
              disabled={nextLoading}
            >
              {step < 1 ? "PRÓXIMO" : "ENVIAR CONVITES"}
            </Button> 
          ) : (
          <Button
            style={{
              fontSize: 12,
              padding: "8px 40px",
              opacity: users.length > 0 ? 1 : 0.4,
            }}
            variant={users.length < 1 ? "outlined" : "contained"}
            color={users.length < 1 ? "secondary" : "primary"}
            onClick={users.length > 0 ? handleNextStep : () => { }}
            disabled={nextLoading}
          >
            {step < 1 ? "PRÓXIMO" : "ENVIAR CONVITES"}
          </Button> 
          )}
        </ModalFooter>
      </ModalCenter>
    </Modal>
  );
}
