import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import * as XLSX from "xlsx";

import Content from "../components/content";
import Loader from "../components/loader";
import { useImpersonation } from "../global/impersonation";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BasicTitle from "../components/title";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Summary from "../components/summary_refund";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";

import Api from "../libs/api";
import Storage from "../libs/storage";
import Helper from "../libs/helper";

const Holder = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    overflow: auto;
`;

const Filters = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`;

const LeftFilters = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
`;

const RightFilters = styled.div`
    min-width: 100px;
    display: flex;
    align-items: center;
`;

const Tag = styled.div`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    padding: 4px 10px;
    border-radius: 8px;

    &.pendente {
        color: #181122;
        border: 1px solid #181122;
    }
    &.aprovado {
        color: #57c4c2;
        border: 1px solid #57c4c2;
    }
    &.rejeitado {
        color: #de6061;
        border: 1px solid #de6061;
    }
    &.pago {
        color: #57c4c2;
        border: 1px solid #57c4c2;
    }
`;

const Data = styled.div`
    margin-bottom: 40px;
`;

const ModalCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 688px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalRejector = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 380px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgb(144, 144, 144);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalPadding = styled.div`
    padding: 20px;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 180px;
    border: 2px solid;
    border-color: #e6e6e6 #ffffff #e6e6e6 #ffffff;
    resize: none;
    font-size: 14px;
    padding: 15px;
    margin-bottom: 20px;
`;

const RejectButton = styled.button`
    display: flex;
    margin: 0 auto;
    border: 2px solid #000000;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #ffffff;
    font-weight: 600;
    margin-top: 5px;
`;

const ErrorMessage = styled.p`
    color: red;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 13px;
`;

const ModalLeft = styled.img`
    position: absolute;
    top: 50%;
    left: -60px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-top: -20px;
`;

const ModalRight = styled.img`
    position: absolute;
    top: 50%;
    right: -60px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-top: -20px;
`;

const ModalClose = styled.img`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const ModalRefundView = styled.div`
    display: flex;
    align-items: center;
    max-height: 95%;
    height: 800px;
`;
const ModalRefundData = styled.div`
    width: 100%;
    height: 600px;
    max-height: 90%;
    overflow: scroll;
`;
const ModalRefundImage = styled.div`
    width: 100%;
    height: 100%;
    max-height: 600px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin: 15px;
`;

const ModalRefundImageHolder = styled.div`
    height: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
`;

const ModalRefundImageFile = styled.img`
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 5px;
`;

const ModalRefundImageActions = styled.div`
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalRefundDataLine = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-bottom: none;
    &.last {
        border-bottom: 1px solid #e6e6e6;
    }
`;
const ModalRefundDataBlock = styled.div`
    width: 100%;
    padding: 24px 40px;
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;

    &.last {
        border-left: none;
    }
`;
const ModalRefundDataBlockTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    margin-bottom: 16px;
`;
const ModalRefundDataBlockText = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #181122;
`;

const Line = styled.div`
    background: none;
    border: none;
    border: 1px solid #e6e6e6;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    clear: both;
`;

const ImageClickable = styled.img`
    cursor: pointer;
`;

const TextModalExport = styled.h3`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #181122;
    margin-bottom: 16px;
    text-align: center;
`;

const TextRefundId = styled.h3`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #181122;
    text-align: center;
`;

const SelectedRefundImage = styled.div`
    display: flex;
    alignitems: center;
    justifycontent: center;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const NoRefund = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 180px;
    /* height: 446px; */
`;

const NoRefundImg = styled.img`
    height: 346px;
    margin-bottom: 100px;
`;

export default function Refunds() {
    let api = Api();
    let storage = Storage();
    let helper = Helper();
    const { impersonateCompanyId } = useImpersonation();

    const [year, setYear] = useState(2025);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 25,
        hasNextPage: true,
    });
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [projects, setProjects] = useState([]);
    const [usersActive, setUsersActive] = useState([]);
    const [rejectReason, setRejectReason] = useState("");
    const [batchReject, setBatchReject] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [profile, setProfile] = useState("");

    useEffect(() => {
        async function loadProfile() {
          const data = await storage.getItem("data");
          if (data && data.profile) {
            setProfile(data.profile);
          }
        }
        loadProfile();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const [openFilter, setOpenFilter] = useState(false);
    const [openFilterDay, setOpenFilterDay] = useState(false);
    const [openRejector, setOpenRejector] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [startDay, setStartDay] = useState(null);
    const [startMonth, setStartMonth] = useState(null);
    const [startYear, setStartYear] = useState(null);
    const [endDay, setEndDay] = useState(null);
    const [endMonth, setEndMonth] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [errorMessageDate, setErrorMessageDate] = useState(false);
    const [refreshSummary, setRefreshSummary] = useState(false);
    const [applyFilters, setApplyFilters] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);

    useEffect(() => {
        if (profile && profile.permissions) {
            setCanPay(profile.permissions.refundPay);
            setCanApprove(profile.permissions.refundApprove);

            setCompanyId(
                profile.company?.id ? parseInt(atob(profile.company.id).split(":")[1]): 0
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const handleStartDateChange = (event) => {
        const [year, month, day] = event.target.value.split("-");

        if (year && month && day) {
            setStartYear(year);
            setStartMonth(month);
            setStartDay(day);
        }
    };

    const handleEndDateChange = (event) => {
        const [year, month, day] = event.target.value.split("-");

        if (year && month && day) {
            setEndYear(year);
            setEndMonth(month);
            setEndDay(day);
        }
    };

    const handleConfirmFilterDate = () => {
        if (!startYear || !startMonth || !startDay) {
            setErrorMessageDate("Por favor, preencha pelo menos a data inicial.");
            return;
        }
    
        let finalYear = endYear || startYear;
        let finalMonth = endMonth || startMonth;
        let finalDay = endDay || startDay;
    
        const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
        const endDate = new Date(`${finalYear}-${finalMonth}-${finalDay}`);
    
        if (endDate < startDate) {
            setErrorMessageDate("A data final não pode ser anterior à data inicial.");
            return;
        }
    
        setErrorMessageDate("");
    
        setStartDay(startDay);
        setEndDay(finalDay);
        setStartMonth(startMonth);
        setEndMonth(finalMonth);
        setStartYear(startYear);
        setEndYear(finalYear);
        setOpenFilterDay(false);
    
        setFiltersApplied(true);
        setApplyFilters(true);
    };
    

    const handleDeleteDate = () => {
        setStartDay(null);
        setStartMonth(null);
        setStartYear(null);
        setEndDay(null);
        setEndMonth(null);
        setEndYear(null);
    };

    const statuses = ["", "PENDENTE", "APROVADO", "REJEITADO", "PAGO"];
    const statusClasses = {
        1: "pendente",
        2: "aprovado",
        3: "rejeitado",
        4: "pago",
    };
    const suspicions = {
        0: "Recibo Repetido",
        1: "Recibo Similar",
        2: "Sem Recibo",
        3: "Sem Valor",
    };

    const handleRejectButton = async (refund) => {
        if (rejectReason === "") {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
    
            const wasPaidWithCredit = refund?.paidWithCredit || false;
    
            if (batchReject) {
                await updateRefunds(3, rejectReason, wasPaidWithCredit);
                getRefunds(false);
            } else {
                await updateRefund(
                    selectedRefund.id,
                    3,
                    wasPaidWithCredit,
                    true,
                    rejectReason
                );
                getRefunds(false);
            }
    
            handleRejectorClose();
        }
    };

    useEffect(() => {
        if (openRejector === false) {
            getRefunds();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openRejector]);

    const RenderDetails = (props) => {
        const { value } = props;
        // React.useLayoutEffect(() => {}, []);
        return (
            <>
                {value.availableCredit > 0 && (
                    <Tooltip
                        title={helper.floatToMoney(value.availableCredit)}
                        placement="top"
                        arrow
                    >
                        <img
                            src="/img/zap.svg"
                            alt={helper.floatToMoney(value.availableCredit)}
                        />
                    </Tooltip>
                )}
                {value.corporate && (
                    <Tooltip title="Cartāo Corporativo" placement="top" arrow>
                        <img
                            src="/img/corporate.svg"
                            alt="Cartāo Corporativo"
                        />
                    </Tooltip>
                )}
                {value.comment && value.comment.length > 0 && (
                    <Tooltip title={value.comment} placement="top" arrow>
                        <img src="/img/comment.svg" alt={value.comment} />
                    </Tooltip>
                )}
                {value.receipt && (
                    <Tooltip title={"Ver Recibo"} placement="top" arrow>
                        <ImageClickable
                            src="/img/receipt.svg"
                            alt="Recibo"
                            onClick={() => {
                                window.open(value.receipt, "_blank");
                            }}
                        />
                    </Tooltip>
                )}
                {value.suspicious !== null &&
                    value.suspicious !== undefined && (
                        <Tooltip
                            title={suspicions[value.suspicious]}
                            placement="top"
                            arrow
                        >
                            <img
                                src="/img/suspicious.svg"
                                alt={suspicions[value.suspicious]}
                            />
                        </Tooltip>
                    )}
            </>
        );
    };

    const RenderStatus = (props) => {
        const { value } = props;
        // React.useLayoutEffect(() => {}, []);

        if (value === 1) {
            return <Tag className="pendente">PENDENTE</Tag>;
        }
        if (value === 2) {
            return <Tag className="aprovado">APROVADO</Tag>;
        }
        if (value === 3) {
            return <Tag className="rejeitado">REJEITADO</Tag>;
        }
        if (value === 4) {
            return <Tag className="pago">PAGO</Tag>;
        }
        return <>{value}</>;
    };

    const RenderValue = (props) => {
        const { value } = props;
        // React.useLayoutEffect(() => {}, []);
        return helper.floatToMoney(value);
    };

    const RenderActions = ({ value }) => {
        const getIcon = (action) => {
            switch (action) {
                case "approve":
                    if (value.status === 4)
                        return "/img/icons_action/check_disabled.png";
                    if (value.status === 2)
                        return "/img/icons_action/check_approved.png";
                    if (value.status === 3)
                        return "/img/icons_action/check_active.png";
                    return "/img/icons_action/check_active.png";

                case "pay":
                    if (value.status === 4 && !value.paidWithCredit)
                        return "/img/icons_action/cipher_approved.png";
                    if (value.status !== 2)
                        return "/img/icons_action/cipher_disabled.png";
                    return "/img/icons_action/cipher_active.png";

                case "pay_with_credit":
                    if (value.status === 4 && value.paidWithCredit)
                        return "/img/icons_action/circle_check_approved.png";
                    if (
                        value.status === 2 &&
                        value.availableCredit >= value.value
                    )
                        return "/img/icons_action/circle_check_active.png";
                    return "/img/icons_action/circle_check_disabled.png";

                case "reject":
                    if (value.status === 3)
                        return "/img/icons_action/reject_approved.png";
                    if (
                        value.status === 1 ||
                        value.status === 2 ||
                        value.status === 4
                    )
                        return "/img/icons_action/reject_active.png";
                    return "/img/icons_action/reject_disabled.png";

                case "rotate":
                    if (
                        value.status === 2 ||
                        value.status === 3 ||
                        value.status === 4
                    )
                        return "/img/icons_action/rotate.png";
                    return "";

                default:
                    return "";
            }
        };

        const getTooltipText = (action) => {
            switch (action) {
                case "pay":
                    if (value.status === 4 && !value.paidWithCredit)
                        return "Pago";
                    return "Pagar";

                case "pay_with_credit":
                    if (value.status === 4 && value.paidWithCredit)
                        return "Pago com crédito";
                    return "Pagar com crédito";

                case "approve":
                    if (value.status === 2) return "Aprovado";
                    return "Aprovar";

                case "reject":
                    if (value.status === 3) return "Rejeitado";
                    return "Rejeitar";

                case "rotate":
                    return "Retornar para pendente";

                default:
                    return "";
            }
        };

        return (
            <>
                {impersonateCompanyId !== null ? (
                    <>
                        <Tooltip title={getTooltipText("approve")} placement="top" arrow>
                            <img
                                src="img/icons_action/check_approved.png"
                                alt="Aprovar"
                                style={{
                                    cursor: "not-allowed",
                                    marginRight: 16,
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        </Tooltip>

                        <Tooltip title={getTooltipText("reject")} placement="top" arrow>
                            <img
                                src="img/icons_action/reject_disabled.png"
                                alt="Rejeitar"
                                style={{
                                    cursor: "not-allowed",
                                    marginRight: 16,
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        </Tooltip>

                        {!value.corporate && (
                            <>
                                {canPay && (
                                    <>
                                        <Tooltip title={getTooltipText("pay")} placement="top" arrow>
                                            <img
                                                src="img/icons_action/cipher_disabled.png"
                                                alt="Pagar"
                                                style={{
                                                    cursor: "not-allowed",
                                                    marginRight: 16,
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            title={getTooltipText("pay_with_credit")}
                                            placement="top"
                                            arrow
                                        >
                                            <img
                                                src="img/icons_action/circle_check_disabled.png"
                                                alt="Pagar com crédito"
                                                style={{
                                                    cursor: "not-allowed",
                                                    marginRight: 16,
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        </Tooltip>
                                    </>
                                )}
                                {value.status === 2 || value.status === 3 ? (
                                    <Tooltip title={getTooltipText("rotate")} placement="top" arrow>
                                        <img
                                            src="img/icons_action/rotate_disabled.png"
                                            alt="Reverter"
                                            style={{
                                                cursor: "not-allowed",
                                                marginRight: 16,
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </Tooltip>
                                ) : null}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Tooltip title={getTooltipText("approve")} placement="top" arrow>
                            <img
                                src={getIcon("approve")}
                                alt="Aprovar"
                                style={{
                                    cursor: value.status === 4 ? "not-allowed" : "pointer",
                                    marginRight: 16,
                                    width: 20,
                                    height: 20,
                                }}
                                onClick={
                                    value.status === 1 || value.status === 3
                                        ? () => updateRefund(value.id, 2, false, false)
                                        : null
                                }
                            />
                        </Tooltip>
                        <Tooltip title={getTooltipText("reject")} placement="top" arrow>
                            <img
                                src={getIcon("reject")}
                                alt="Rejeitar"
                                style={{
                                    cursor:
                                        value.status === 1 ||
                                        value.status === 2 ||
                                        value.status === 4
                                            ? "pointer"
                                            : "not-allowed",
                                    marginRight: 16,
                                    width: 20,
                                    height: 20,
                                }}
                                onClick={
                                    value.status === 1 ||
                                    value.status === 2 ||
                                    value.status === 4
                                        ? async () => {
                                            getRefund(value.id).then(() =>
                                                setOpenRejector(true)
                                            );
                                        }
                                        : null
                                }
                            />
                        </Tooltip>
                        {!value.corporate && (
                            <>
                                {canPay && (
                                    <>
                                        <Tooltip title={getTooltipText("pay")} placement="top" arrow>
                                            <img
                                                src={getIcon("pay")}
                                                alt="Pagar"
                                                style={{
                                                    cursor:
                                                        value.status === 2
                                                            ? "pointer"
                                                            : "not-allowed",
                                                    marginRight: 16,
                                                    width: 20,
                                                    height: 20,
                                                }}
                                                onClick={
                                                    value.status === 2
                                                        ? () =>
                                                            updateRefund(
                                                                value.id,
                                                                4,
                                                                false,
                                                                false
                                                            )
                                                        : null
                                                }
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            title={getTooltipText("pay_with_credit")}
                                            placement="top"
                                            arrow
                                        >
                                            <img
                                                src={getIcon("pay_with_credit")}
                                                alt="Pagar com crédito"
                                                style={{
                                                    cursor:
                                                        value.status === 2 &&
                                                        value.availableCredit >= value.value
                                                            ? "pointer"
                                                            : "not-allowed",
                                                    marginRight: 16,
                                                    width: 20,
                                                    height: 20,
                                                }}
                                                onClick={
                                                    value.status === 2 &&
                                                    value.availableCredit >= value.value
                                                        ? () =>
                                                            updateRefund(
                                                                value.id,
                                                                4,
                                                                true,
                                                                false
                                                            )
                                                        : null
                                                }
                                            />
                                        </Tooltip>
                                    </>
                                )}
                                {value.status === 2 || value.status === 3 ? (
                                    <Tooltip title={getTooltipText("rotate")} placement="top" arrow>
                                        <img
                                            src={getIcon("rotate")}
                                            alt="Reverter"
                                            style={{
                                                cursor: "pointer",
                                                marginRight: 16,
                                                width: 20,
                                                height: 20,
                                            }}
                                            onClick={() =>
                                                updateRefund(value.id, 1, false, false)
                                            }
                                        />
                                    </Tooltip>
                                ) : null}
                            </>
                        )}
                    </>
                )}
            </>
        );
    };
    const columns = [
        { field: "id", headerName: "ID", width: 100 },
        { field: "date", headerName: "Data", width: 140 },
        { field: "user", headerName: "Usuário", width: 200 },
        { field: "email", headerName: "E-mail", width: 200 },
        {
            field: "project",
            headerName: "Centro de custo",
            width: 140,
        },
        {
            field: "category",
            headerName: "Categoria",
            width: 140,
        },
        {
            field: "value",
            headerName: "Valor",
            type: "number",
            renderCell: RenderValue,
        },
        {
            field: "details",
            headerName: "Detalhes",
            sortable: false,
            renderCell: RenderDetails,
            disableExport: true,
        },
        {
            field: "status",
            width: 140,
            headerName: "Status",
            renderCell: RenderStatus,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 200,
            renderCell: RenderActions,
        },
    ];

    const [loadingRefunds, setLoadingRefunds] = useState();

    const isFetching = useRef(false);

    const getRefunds = async (hidden) => {
        if (!isFetching.current) {
            isFetching.current = true;
            setLoading(true);

            if (!hidden) {
                setPageState((old) => ({ ...old, isLoading: true }));
            }

            const afterCursor = pageState.page > 1 ? pageState.endCursor : null;

            const data = await storage.getItem("data");
            if (!data) return;
            let response = await api.getRefunds(
                data.token,
                year,
                month,
                startDay,
                endDay,
                startMonth,
                endMonth,
                startYear,
                endYear,
                selectedUsers,
                selectedStatus,
                selectedCategories,
                selectedProjects,
                corporate,
                impersonateCompanyId,
                afterCursor,
                pageState.pageSize
            );

            let edges = response.data.allZagviewRefunds;
            response = response.data.allZagviewRefunds.edges;

            let _rows = [];
            for (let index = 0; index < response.length; index++) {
                const refund = response[index].node;
                const _refund = {
                    id: parseInt(atob(refund.id).split(":")[1]),
                    date: helper.dateFromString(refund.createdAt),
                    user_id: parseInt(atob(refund?.user?.id).split(":")[1]),
                    user: refund.user.name,
                    role: refund.user.role,
                    email: refund.user.email,
                    project: refund.project ? refund.project.name : "-",
                    category: refund.category ? refund.category.name : "-",
                    value: parseFloat(refund.value),
                    paid_at: refund.paidAt,
                    details: {
                        corporate: refund.isNotRefundable,
                        receipt: refund.receiptFiles.original,
                        suspicious: refund.suspicion,
                        comment: refund.comment,
                        availableCredit: refund.user.availableCredit,
                    },
                    actions: {
                        id: refund.id,
                        value: parseFloat(refund.value),
                        availableCredit: parseFloat(
                            refund.user.availableCredit
                        ),
                        status: refund.status,
                        paidWithCredit: refund.paidWithCredit,
                        corporate: refund.isNotRefundable,
                    },
                    refundLogs: {
                        who: refund?.refundLogs?.logs[0]?.who,
                        status: refund?.refundLogs?.logs[0]?.status,
                        when: refund?.refundLogs?.logs[0]?.when
                            ? refund?.refundLogs?.logs[0]?.when
                            : null,
                        reason: refund?.refundLogs?.logs[0]?.reason,
                    },
                    suspectedRefundFiles: {
                        suspectRefundImage:
                            refund?.suspectedRefundFiles?.suspectRefundImage,
                        suspectRefundId:
                            refund?.suspectedRefundFiles?.suspectRefundId,
                    },
                    status: refund?.status,
                };

                let shouldPush = true;
                if (corporate !== null) {
                    if (corporate && !refund.isNotRefundable) {
                        shouldPush = false;
                    } else if (!corporate && refund.isNotRefundable) {
                        shouldPush = false;
                    }
                }

                if (receipt !== null) {
                    if (receipt && !refund.receiptFiles.original) {
                        shouldPush = false;
                    } else if (!receipt && refund.receiptFiles.original) {
                        shouldPush = false;
                    }
                }

                if (warning !== null) {
                    if (warning && !refund.suspicion) {
                        shouldPush = false;
                    } else if (!warning && refund.suspicion) {
                        shouldPush = false;
                    }
                }

                if (shouldPush) {
                    _rows.push(_refund);
                }
            }

            setLoadingRefunds(false);
            setPageState((old) => ({
                ...old,
                isLoading: false,
                data: _rows,
                total: edges.totalCount,
                endCursor: edges.pageInfo.endCursor,
                hasNextPage: edges.pageInfo.hasNextPage,
            }));

            setLoading(false);
            isFetching.current = false;
        }
    };

    const [allRefunds, setAllRefunds] = useState([]);
    const [isFetchingAllRefunds, setIsFetchingAllRefunds] = useState(false);
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const [isXLSXLoading, setIsXLSXLoading] = useState(false);
    const requestCounter = useRef(0);

    const refundsRaw = async () => {
        setIsFetchingAllRefunds(true);
        setAllRefunds([]);

        const currentRequestId = ++requestCounter.current;

        let allFetchedRefunds = [];

        try {
            const data = await storage.getItem("data");
        
            let companyId = impersonateCompanyId || (profile?.company?.id
                ? parseInt(atob(profile.company.id).split(":")[1], 10)
                : null);
        
            let response = await api.getRefundsRaw(
                data.token,
                year,
                month,
                startDay,
                endDay,
                startMonth,
                endMonth,
                startYear,
                endYear,
                selectedUsers,
                selectedStatus,
                selectedCategories,
                selectedProjects,
                corporate,
                impersonateCompanyId,
                companyId
            );
        
            if (currentRequestId !== requestCounter.current) {
                return;
            }
        
            if (response?.data?.refunds?.length > 0) {
                allFetchedRefunds = response.data.refunds.map(refund => ({
                    id: refund.id,
                    date: helper.dateFromString(refund.createdAt),
                    userId: refund.userId,
                    user: refund.user,
                    email: refund.email,
                    comment: refund.comment,
                    project: refund.project ? refund.project.name : "-",
                    corporate: refund.isNotRefundable,
                    paidWithCredit: refund.paidWithCredit,
                    category: refund.category ? refund.category.name : "-",
                    value: parseFloat(refund.value),
                    paidAt: refund.paidAt,
                    logs: {
                        who: refund?.logs?.who || "-",
                        status: refund?.logs?.status || "-",
                        when: refund?.logs?.when || "-",
                        reason: refund?.logs?.reason || "-",
                    },
                    status: parseInt(refund?.status) || "-",
                }));
            }
        
            setAllRefunds(allFetchedRefunds);
        } catch (error) {
        } finally {
            if (currentRequestId === requestCounter.current) {
                setIsFetchingAllRefunds(false);
            }
        }
    };

    useEffect(() => {
        if (allRefunds.length > 0 && !isFetchingAllRefunds && isCSVLoading) {
            downloadIntegratedReport("csv");
            setIsCSVLoading(false);
        }

        if (allRefunds.length > 0 && !isFetchingAllRefunds && isXLSXLoading) {
            downloadIntegratedReport("xlsx");
            setIsXLSXLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRefunds, isFetchingAllRefunds, isCSVLoading, isXLSXLoading]);

    const downloadCSVButton = () => {
        if (selectedIntegratedReport === "5") {
            generateExport("bbraun");
            showModalMessageExport();
        } else if (allRefunds.length === 0 && isFetchingAllRefunds) {
            setIsCSVLoading(true);
        } else {
            downloadIntegratedReport("csv");
        }
    };

    const downloadXLSXButton = () => {
        if (allRefunds.length === 0 && isFetchingAllRefunds) {
            setIsXLSXLoading(true);
        } else {
            downloadIntegratedReport("xlsx");
        }
    };

    useEffect(() => {
        getRefunds(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.page, pageState.pageSize]);

    const getCategories = async () => {
        //setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getCategories(
            data.token,
            impersonateCompanyId
        );
        response = response.data.allCategories.edges;
        let _categories = [];
        for (let index = 0; index < response.length; index++) {
            const category = response[index].node;
            _categories.push(category);
        }
        _categories.sort((a, b) => a.name.localeCompare(b.name));
        setCategories(_categories);
        //setLoading(false);
    };


    const getProjects = async () => {
        //setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getProjects(data.token, impersonateCompanyId);
        response = response.data.allProjects.edges;
        let _projects = [];
        for (let index = 0; index < response.length; index++) {
            const project = response[index].node;
            _projects.push(project);
        }
        _projects.sort((a, b) => a.name.localeCompare(b.name));
        setProjects(_projects);
        //setLoading(false);
    };

    const getActiveUsers = async () => {
        //setLoading(true);
        const data = await storage.getItem("data");
        if (!data) return;
        let response = await api.getActiveUsers(
            data.token,
            impersonateCompanyId
        );
        response = response.data.allZagviewUsers.edges;
        let _users = [];
        for (let index = 0; index < response.length; index++) {
            const user = response[index].node;
            _users.push(user);
        }
        setUsersActive(_users);
        //setLoading(false);
    };

    useEffect(() => {
        getCategories();
        getProjects();
        getActiveUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterOpen = () => setOpenFilter(true);
    const handleFilterClose = () => {
        setOpenFilter(false);
    };
    const handleFilterDayOpen = () => setOpenFilterDay(true);
    const handleFilterDayClose = () => {
        setOpenFilterDay(false);
        setStartDay(null);
        setEndDay(null);
        setStartMonth(null);
        setEndMonth(null);
        setStartYear(null);
        setEndYear(null);
        setErrorMessageDate(false);
    };

    const handleRejectorClose = () => {
        setOpenRejector(false);
        setRejectReason("");
    };

    const [selectedUsers, setSelectedUsers] = useState([]);
    // const getUserName = (id) => {
    //   for (let index = 0; index < users.length; index++) {
    //     const _user = users[index];
    //     if (id === _user.id) {
    //       return _user.name;
    //     }
    //   }
    //   return "";
    // };
    // const handleChangeUsers = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   setSelectedUsers(typeof value === "string" ? value.split(",") : value);
    // };

    const [selectedStatus, setSelectedStatus] = useState([]);
    const getStatusName = (id) => {
        return statuses[parseInt(id)];
    };
    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(typeof value === "string" ? value.split(",") : value);
    };

    const [selectedProjects, setSelectedProjects] = useState([]);
    // const getProjectName = (id) => {
    //   for (let index = 0; index < projects.length; index++) {
    //     const _project = projects[index];
    //     if (id === _project.id) {
    //       return _project.name;
    //     }
    //   }
    //   return "";
    // };
    // const handleChangeProjects = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   setSelectedProjects(typeof value === "string" ? value.split(",") : value);
    // };

    const [selectedCategories, setSelectedCategories] = useState([]);
    // const getCategoryName = (id) => {
    //   for (let index = 0; index < categories.length; index++) {
    //     const _category = categories[index];
    //     if (id === _category.id) {
    //       return _category.name;
    //     }
    //   }
    //   return "";
    // };
    // const handleChangeCategories = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   setSelectedCategories(typeof value === "string" ? value.split(",") : value);
    // };

    const [corporate, setCorporate] = useState(null);
    const handleChangeCorporate = (event) => {
        setCorporate(event.target.value);
    };

    const [receipt, setReceipt] = useState(null);
    const handleChangeReceipt = (event) => {
        setReceipt(event.target.value);
    };

    const [warning, setWarning] = useState(null);
    const handleChangeWarning = (event) => {
        setWarning(event.target.value);
    };

    const [selectedCells, setSelectedCells] = useState([]);

    const handleChangeCellSelection = (refunds) => {
        setSelectedCells(refunds);
    };


    useEffect(() => {
        if (profile && profile.company?.id) {
            refundsRaw();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    useEffect(() => {
        if (applyFilters) {
            getRefunds(false);
            setApplyFilters(false);
            refundsRaw();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        applyFilters,
        year,
        month,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        corporate,
        receipt,
        warning,
        selectedUsers,
        selectedStatus,
        selectedCategories,
        selectedProjects,
        pageState.page,
        pageState.pageSize,
        profile
    ]);

    // ["", "PENDENTE", "APROVADO", "REJEITADO", "PAGO"];
    const updateRefunds = async (status, reason, impersonateCompanyId) => {
        if (selectedCells.length > 0) {
            let _refunds = [];
            for (let index = 0; index < selectedCells.length; index++) {
                const refund = selectedCells[index];
                const data = {
                    id: btoa(`RefundNode:${refund}`),
                    status: status,
                    reason: reason,
                };
                _refunds.push(btoa(JSON.stringify(data)));
            }
            setLoading(true);
            const data = await storage.getItem("data");
            await api.updateRefunds(
                data.token,
                JSON.stringify(_refunds),
                impersonateCompanyId
            );
            getRefunds();
            setRefreshSummary(prev => !prev);
            setLoading(false);

        }
    };

    const updateRefund = async (
        id,
        status,
        pay_with_credit,
        fromModal,
        reason
    ) => {
        setLoading(true);
        let _refunds = [
            btoa(
                JSON.stringify({
                    id,
                    status,
                    pay_with_credit,
                    reason,
                })
            ),
        ];
        const data = await storage.getItem("data");
        await api.updateRefunds(
            data.token,
            JSON.stringify(_refunds),
            impersonateCompanyId
        );
        if (fromModal) {
            getRefund(id);
        }
        getRefunds();
        setRefreshSummary(prev => !prev);
        setLoading(false);
    };

    const [selectedRefund, setSelectedRefund] = useState();
    const [openRefund, setOpenRefund] = useState(false);

    const handleRefundOpen = () => setOpenRefund(true);
    const handleRefundClose = () => setOpenRefund(false);

    const getRefund = async (id) => {
        setLoading(true);
        const data = await storage.getItem("data");
        let response = await api.getRefund(
            data.token,
            btoa(`RefundNode:${atob(id).split(":")[1]}`),
            impersonateCompanyId
        );
        const refund = response.data.refund;
        setSelectedRefund(refund);
        setLoading(false);
    };

    const [openedIndex, setOpenedIndex] = useState();

    const handleRowClick = async (params, event, details) => {
        // console.log(params, event.target.nodeName, details);
        if (event.target.nodeName !== "IMG") {
            for (let index = 0; index < pageState.data.length; index++) {
                const _refund = pageState.data[index];
                if (_refund.actions.id === params.row.actions.id) {
                    setOpenedIndex(index);
                }
            }
            await getRefund(params.row.actions.id);
            handleRefundOpen();
        }
    };
    

    const generateExport = async (taskType) => {
        const data = await storage.getItem("data");

        const company_id = profile?.company?.id;
        const decode_company_id = atob(company_id).split(":")[1];
        const name = data.profile.name;
        const email = data.profile.email;
        const selectedStatusFormatted = Array.isArray(selectedStatus)
            ? selectedStatus.map((status) => parseInt(status, 10))
            : [parseInt(selectedStatus, 10)];
        const notRefundable = corporate;

        await api.sendToQueue(
            data.token,
            taskType,
            decode_company_id,
            name,
            email,
            month,
            year,
            startDay,
            startMonth,
            startYear,
            endDay,
            endMonth,
            endYear,
            notRefundable,
            selectedStatusFormatted,
            selectedUsers,
            selectedProjects,
            selectedCategories,
            impersonateCompanyId,
            pageState && pageState.total ? pageState.total : 0
        );

        if (taskType !== "pdf") {
            showModalMessageExport();
        }
    };

    const [openIntegratedReport, setOpenIntegratedReport] = useState(false);
    const [openModalMessageExport, setOpenModalMessageExport] = useState(false);

    const showIntegratedReportOptions = async () => {
        setOpenIntegratedReport(true);
    };

    const handleIntegratedReportClose = () => {
        setOpenIntegratedReport(false);
        setSelectedIntegratedReport("1");
    };

    const showModalMessageExport = async () => {
        setOpenModalMessageExport(true);
    };

    const closeModalMessageExport = () => {
        setOpenModalMessageExport(false);
        setOpenIntegratedReport(false);
    };

    const [selectedIntegratedReport, setSelectedIntegratedReport] =
        useState("1");
    const handleChangeSelectedIntegratedReport = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedIntegratedReport(value);
    };

    const downloadIntegratedReport = async (type) => {
        const mapStatus = (value) => {
            if (value === 1) {
                return "PENDENTE";
            }
            if (value === 2) {
                return "APROVADO";
            }
            if (value === 3) {
                return "REJEITADO";
            }
            if (value === 4) {
                return "PAGO";
            }
            return value;
        };

        const types = [
            "",
            "resumido",
            "completo",
            "agrupado-cc-categoria",
            "nibo",
            "Lançamentos -> Detalhado",
        ];

        const _name =
            `zag-export-${year}-${months[month]}-${types[selectedIntegratedReport]}.${type}`.toLowerCase();

        let _rows = [[]];

        let result = [];

        if (selectedIntegratedReport === "1") {
            _rows = [["Data", "Nome", "Centro de Custo", "Categoria", "Valor"]];

            const groupedByUser = {};

            allRefunds.forEach((value) => {
                if (!groupedByUser[value.user]) {
                    groupedByUser[value.user] = [];
                }
                groupedByUser[value.user].push(value);
            });

            const result = Object.values(groupedByUser).flat();
            result.sort((a, b) => b.id - a.id);

            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                const value = parseFloat((element.value * -1).toFixed(2));

                _rows.push([
                    element.date,
                    element.user,
                    element.project,
                    element.category,
                    value,
                ]);
            }
        }

        if (selectedIntegratedReport === "2") {
            _rows = [
                [
                    "ID",
                    "Data",
                    "ID Usuário",
                    "Usuário",
                    "Email",
                    "Centro de Custo",
                    "Categoria",
                    "Valor",
                    "Pago com Crédito",
                    "Cartão Corporativo",
                    "Status",
                    "Data do Pagamento",
                    "Data da Última Atualização",
                    "Moderado Por",
                    "Pago Por",
                    "Descrição",
                ],
            ];

            const groupedByUser = {};

            allRefunds.forEach((value) => {
                if (!groupedByUser[value.user]) {
                    groupedByUser[value.user] = [];
                }
                groupedByUser[value.user].push(value);
            });

            const result = Object.values(groupedByUser).flat();
            result.sort((a, b) => b.id - a.id);

            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                let status = mapStatus(element.status);
                const value = parseFloat((element.value * -1).toFixed(2));
                const whoPaid =
                    element.status === 4 && element.logs.who
                        ? element.logs.who
                        : "";
                const paid =
                    element.status === 4 && element.paidAt
                        ? helper.dateFromString(element.paidAt)
                        : "";

                _rows.push([
                    element.id,
                    element.date,
                    element.userId,
                    element.user,
                    element.email,
                    element.project,
                    element.category,
                    value,
                    element.paidWithCredit ? "SIM" : "NÃO",
                    element.corporate ? "SIM" : "NÃO",
                    status,
                    paid,
                    element.logs && element.logs.when !== "-"
                        ? helper.dateFromString(element.logs.when)
                        : "",
                    element.logs.who,
                    whoPaid,
                    element.comment ? '"' + element.comment.replace(/\r?\n|\r/g, " ") + '"' : "-",
                ]);
            }
        }

        if (selectedIntegratedReport === "3") {
            _rows = [["Data", "Centro de Custo e Categoria", "Valor"]];

            let helperValue = {};
            result = allRefunds.reduce(function (r, o) {
                var key = o.project + "-" + o.category;
                if (!helperValue[key]) {
                    helperValue[key] = Object.assign({}, o);
                    r.push(helperValue[key]);
                } else {
                    helperValue[key].value += o.value;
                }
                return r;
            }, []);
            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                const value = parseFloat((element.value * -1).toFixed(2));

                _rows.push([
                    element.date,
                    `${element.project ? element.project : "--"} >> ${
                        element.category ? element.category : "--"
                    }`,
                    value,
                ]);
            }
        }

        if (selectedIntegratedReport === "4") {
            _rows = [
                ["Data", "Usuário -> Centro de Custo - >Categoria", "Valor"],
            ];

            for (let index = 0; index < allRefunds.length; index++) {
                const element = allRefunds[index];
                const value = parseFloat((element.value * -1).toFixed(2));

                _rows.push([
                    element.date,
                    `${element.user} >> ${
                        element.project ? element.project : "--"
                    } >>  ${element.category ? element.category : "--"}`,
                    value,
                ]);
            }
        }

        if (type === "csv") {
            let csvContent =
                "data:text/csv;charset=utf-8," +
                _rows.map((e) => e.join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", _name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else if (type === "xlsx") {
            const worksheet = XLSX.utils.aoa_to_sheet(_rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
            const headerRow = _rows[0];
            const valueColumnIndex = headerRow.findIndex((col) => col.includes("Valor"));
        
            if (valueColumnIndex !== -1) {
                const range = XLSX.utils.decode_range(worksheet["!ref"]);
        
                for (let R = range.s.r + 1; R <= range.e.r; ++R) {
                    const cellRef = XLSX.utils.encode_cell({ r: R, c: valueColumnIndex });
        
                    if (worksheet[cellRef]) {
                        worksheet[cellRef].z = '"R$" #,##0.00';
                    }
                }
            }
            XLSX.writeFile(workbook, _name);
        }
    };


    function toolbarDatagridOptions() {
        return (
            <GridToolbarContainer>
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall"
                    type="button"
                    aria-label="Baixar Relatório PDF"
                    onClick={() => {
                        generateExport("pdf");
                    }}
                    style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: 500,
                        fontSize: "0.8125rem",
                        lineHeight: 1.75,
                        letterSpacing: "0.02857em",
                        textTransform: "uppercase",
                        minWidth: 64,
                        padding: "4px 5px",
                        borderRadius: 4,
                        transition:
                            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        color: "#57C4C2",
                    }}
                >
                    <span
                        className="MuiButton-startIcon MuiButton-iconSizeSmall"
                        style={{ width: 18, height: 18 }}
                    >
                        <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="SaveAltIcon"
                            style={{
                                width: 18,
                                height: 18,
                                margin: "0px 6px",
                                float: "left",
                            }}
                        >
                            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
                        </svg>
                    </span>
                    Gerar PDF
                </button>
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall"
                    type="button"
                    aria-label="Exportar"
                    onClick={showIntegratedReportOptions}
                    style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: 500,
                        fontSize: "0.8125rem",
                        lineHeight: 1.75,
                        letterSpacing: "0.02857em",
                        textTransform: "uppercase",
                        minWidth: 64,
                        padding: "4px 5px",
                        borderRadius: 4,
                        transition:
                            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        color: "#57C4C2",
                    }}
                >
                    <span
                        className="MuiButton-startIcon MuiButton-iconSizeSmall"
                        style={{ width: 18, height: 18 }}
                    >
                        <svg
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="SaveAltIcon"
                            style={{
                                width: 18,
                                height: 18,
                                margin: "0px 6px",
                                float: "left",
                            }}
                        >
                            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
                        </svg>
                    </span>
                    Exportar
                </button>
            </GridToolbarContainer>
        );
    }

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [canPay, setCanPay] = useState(false);
    const [canApprove, setCanApprove] = useState(false);

    const reopenRefundFromIndex = async (_index) => {
        handleRefundClose();
        for (let index = 0; index < pageState.data.length; index++) {
            if (index === _index) {
                const _refund = pageState.data[index];
                await getRefund(_refund.actions.id);
                handleRefundOpen();
            }
        }
    };

    const months = [
        "",
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const allowedCompaniesSap = [1127, 7229, 60, 2595, 6262, 227];

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleConfirmFilterDate();
        }
    };

    return (
        <>
            {loading && <Loader />}
            {selectedRefund && (
                <Modal
                    open={openRefund}
                    onClose={handleRefundClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalCenter
                        style={{
                            width: 1280,
                            maxWidth: "100%",
                            height: 680,
                            maxHeight: "95%",
                        }}
                    >
                        {loading && <Loader />}
                        <ModalClose
                            src="/img/modal_close.svg"
                            onClick={handleRefundClose}
                        />
                        {pageState.data.length > 1 && (
                            <ModalLeft
                                src="/img/refundLeft.svg"
                                onClick={() => {
                                    let _i = openedIndex - 1;
                                    if (_i < 0) {
                                        _i = pageState.data.length - 1;
                                    }
                                    setOpenedIndex(_i);
                                    reopenRefundFromIndex(_i);
                                }}
                            />
                        )}
                        {pageState.data.length > 1 && (
                            <ModalRight
                                src="/img/refundRight.svg"
                                onClick={() => {
                                    let _i = openedIndex + 1;
                                    if (_i >= pageState.data.length) {
                                        _i = 0;
                                    }
                                    setOpenedIndex(_i);
                                    reopenRefundFromIndex(_i);
                                }}
                            />
                        )}
                        <div
                            style={{
                                width: "50%",
                                height: "auto",
                                display: "flex",
                                flexDirection: "space-between",
                                paddingRight: 15,
                            }}
                        >
                            <BasicTitle
                                margin={0}
                                title={`Despesa | ID: ${
                                    atob(selectedRefund.id).split(":")[1]
                                }`}
                            />
                            <Tag
                                className={statusClasses[selectedRefund.status]}
                                style={{
                                    fontWeight: 600,
                                    fontSize: 14,
                                    marginBottom: 16,
                                    lineHeight: "20px",
                                }}
                            >
                                {statuses[selectedRefund.status]}
                            </Tag>
                        </div>
                        <ModalRefundView>
                            <ModalRefundData>
                                <ModalRefundDataLine>
                                    <ModalRefundDataBlock>
                                        <ModalRefundDataBlockTitle>
                                            USUÁRIO
                                        </ModalRefundDataBlockTitle>
                                        <ModalRefundDataBlockText>
                                            {selectedRefund.user.name}
                                        </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                    <ModalRefundDataBlock className="last">
                                        <ModalRefundDataBlockTitle>
                                            DATA DA SOLICITAÇÃO
                                        </ModalRefundDataBlockTitle>
                                        <ModalRefundDataBlockText>
                                            {helper.formatDateTime(
                                                selectedRefund.createdAt
                                            )}
                                        </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                </ModalRefundDataLine>
                                <ModalRefundDataLine>
                                    <ModalRefundDataBlock>
                                        <ModalRefundDataBlockTitle>
                                            CENTRO DE CUSTO
                                        </ModalRefundDataBlockTitle>
                                        <ModalRefundDataBlockText>
                                            {selectedRefund.project
                                                ? selectedRefund.project.name
                                                : "-"}
                                        </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                    <ModalRefundDataBlock className="last">
                                        <ModalRefundDataBlockTitle>
                                            CATEGORIA
                                        </ModalRefundDataBlockTitle>
                                        <ModalRefundDataBlockText>
                                            {selectedRefund.category
                                                ? selectedRefund.category.name
                                                : "-"}
                                        </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                </ModalRefundDataLine>
                                {selectedRefund.comment && (
                                    <ModalRefundDataLine>
                                        <ModalRefundDataBlock>
                                            <ModalRefundDataBlockTitle>
                                                DESCRIÇÃO
                                            </ModalRefundDataBlockTitle>
                                            <ModalRefundDataBlockText>
                                                {selectedRefund.comment}
                                            </ModalRefundDataBlockText>
                                        </ModalRefundDataBlock>
                                    </ModalRefundDataLine>
                                )}
                                <ModalRefundDataLine
                                    className={
                                        selectedRefund.refundLogs.logs
                                            .length === 0
                                            ? "last"
                                            : ""
                                    }
                                >
                                    <ModalRefundDataBlock>
                                        <ModalRefundDataBlockTitle>
                                            VALOR
                                        </ModalRefundDataBlockTitle>
                                        <ModalRefundDataBlockText>
                                            {helper.floatToMoney(
                                                selectedRefund.value
                                            )}
                                        </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                </ModalRefundDataLine>
                                {selectedRefund?.suspectedRefundFiles !==
                                    null && (
                                    <ModalRefundDataLine>
                                        <ModalRefundDataBlock
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <img
                                                src="/img/alert.png"
                                                alt=""
                                                style={{
                                                    width: 40,
                                                    height: 40,
                                                    marginBottom: 16,
                                                }}
                                            />
                                            <ModalRefundDataBlockText
                                                style={{ textAlign: "center" }}
                                            >
                                                <strong>ALERTA:</strong> O
                                                RECIBO SE PARECE MUITO COM OUTRO
                                                UTILIZADO ANTERIORMENTE.
                                            </ModalRefundDataBlockText>
                                        </ModalRefundDataBlock>
                                    </ModalRefundDataLine>
                                )}
                                {selectedRefund.isNotRefundable && (
                                    <ModalRefundDataLine>
                                        <ModalRefundDataBlock>
                                            <ModalRefundDataBlockTitle
                                                style={{ marginBottom: 0 }}
                                            >
                                                DESPESA SOLICITADA POR CARTÃO
                                                CORPORATIVO
                                            </ModalRefundDataBlockTitle>
                                        </ModalRefundDataBlock>
                                    </ModalRefundDataLine>
                                )}

                                {selectedRefund.refundLogs.logs.length > 0 && (
                                    <>
                                        {selectedRefund.refundLogs.logs.length > 0 &&
                                        selectedRefund.refundLogs.logs[0].status !== 1 &&
                                        selectedRefund.refundLogs.logs[0].role !== 0 && (
                                            <ModalRefundDataLine>
                                                <ModalRefundDataBlock>
                                                    <ModalRefundDataBlockTitle>
                                                        {statuses[selectedRefund.refundLogs.logs[0].status]}{" "}POR
                                                    </ModalRefundDataBlockTitle>
                                                    <ModalRefundDataBlockText>
                                                        {selectedRefund.refundLogs.logs[0].who}
                                                    </ModalRefundDataBlockText>
                                                </ModalRefundDataBlock>
                                            </ModalRefundDataLine>
                                        )}
                                        {selectedRefund.refundLogs.logs[0]
                                            .status === 3 &&
                                            selectedRefund.refundLogs.logs[0]
                                                .reason &&
                                            selectedRefund.refundLogs.logs[0]
                                                .reason.length > 0 && (
                                                <ModalRefundDataLine>
                                                    <ModalRefundDataBlock>
                                                        <ModalRefundDataBlockTitle>
                                                            MOTIVO DA REJEIÇÃO
                                                        </ModalRefundDataBlockTitle>
                                                        <ModalRefundDataBlockText>
                                                            {
                                                                selectedRefund
                                                                    .refundLogs
                                                                    .logs[0]
                                                                    .reason
                                                            }
                                                        </ModalRefundDataBlockText>
                                                    </ModalRefundDataBlock>
                                                </ModalRefundDataLine>
                                            )}
                                    </>
                                )}
                                {selectedRefund.refundLogs.logs.length > 0 && selectedRefund.user.role === 0 && (
                                <ModalRefundDataLine>
                                    <ModalRefundDataBlock>
                                    <ModalRefundDataBlockTitle>
                                        EDITADO POR
                                    </ModalRefundDataBlockTitle>
                                    <ModalRefundDataBlockText>
                                        {selectedRefund.user.name}
                                    </ModalRefundDataBlockText>
                                    </ModalRefundDataBlock>
                                </ModalRefundDataLine>
                                )}
                                {selectedRefund.refundLogs.logs.length > 0 && (
                                    <ModalRefundDataLine className="last">
                                        <ModalRefundDataBlock>
                                            <ModalRefundDataBlockTitle>
                                                HISTÓRICO
                                            </ModalRefundDataBlockTitle>
                                            {selectedRefund.refundLogs.logs
                                                .sort((a, b) => new Date(b.when) - new Date(a.when))
                                                .map((log) => {
                                                    const isEdited = log.role === 0;
                                                    return (
                                                        <ModalRefundDataBlockText key={log.id}>
                                                            <span
                                                                style={{
                                                                    fontWeight: 700,
                                                                    color: isEdited
                                                                        ? ""
                                                                        : log.status === 2 || log.status === 4
                                                                        ? "#57C4C2"
                                                                        : log.status === 3
                                                                        ? "#DE6061"
                                                                        : "",
                                                                }}
                                                            >
                                                                {isEdited ? "EDITADO" : statuses[log.status]}
                                                            </span>{" "}
                                                            POR{" "}
                                                            <span style={{ fontWeight: 700 }}>
                                                                {log.who}
                                                            </span>{" "}
                                                            EM{" "}
                                                            {helper.formatDateTime(log.when)}
                                                        </ModalRefundDataBlockText>
                                                    );
                                                })}
                                        </ModalRefundDataBlock>
                                    </ModalRefundDataLine>
                                )}
                            </ModalRefundData>
                            <ModalRefundImage>
                                <ModalRefundImageHolder>
                                    {selectedRefund.receiptFiles.original && (
                                        <SelectedRefundImage
                                            style={{ flexDirection: "column" }}
                                        >
                                            <ModalRefundImageFile
                                                src={
                                                    selectedRefund.receiptFiles
                                                        .original
                                                }
                                                style={{
                                                    border:
                                                        selectedRefund.status ===
                                                            4 ||
                                                        selectedRefund.status ===
                                                            2
                                                            ? "2px solid rgb(87, 196, 194)"
                                                            : selectedRefund.status ===
                                                              3
                                                            ? "2px solid rgb(222, 96, 97)"
                                                            : "",
                                                }}
                                                onClick={() => {
                                                    window.open(
                                                        selectedRefund
                                                            ?.receiptFiles
                                                            ?.original,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                            {selectedRefund
                                                ?.suspectedRefundFiles
                                                ?.suspectRefundImage && (
                                                <TextRefundId>
                                                    REEMBOLSO{" "}
                                                    {`#${parseInt(
                                                        atob(
                                                            selectedRefund.id
                                                        ).split(":")[1]
                                                    )}`}
                                                </TextRefundId>
                                            )}
                                        </SelectedRefundImage>
                                    )}
                                    {selectedRefund?.suspectedRefundFiles
                                        ?.suspectRefundImage && (
                                        <SelectedRefundImage
                                            style={{
                                                flexDirection: "column",
                                                width: "25%",
                                                height: "auto",
                                                marginLeft: 15,
                                            }}
                                        >
                                            <ModalRefundImageFile
                                                src={
                                                    selectedRefund
                                                        ?.suspectedRefundFiles
                                                        ?.suspectRefundImage
                                                }
                                                style={{
                                                    border:
                                                        selectedRefund.status ===
                                                            4 ||
                                                        selectedRefund.status ===
                                                            2
                                                            ? "2px solid rgb(87, 196, 194)"
                                                            : selectedRefund.status ===
                                                              3
                                                            ? "2px solid rgb(222, 96, 97)"
                                                            : "",
                                                }}
                                                onClick={() => {
                                                    window.open(
                                                        selectedRefund
                                                            ?.suspectedRefundFiles
                                                            ?.suspectRefundImage,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                            {selectedRefund
                                                ?.suspectedRefundFiles
                                                ?.suspectRefundImage && (
                                                <TextRefundId>
                                                    REEMBOLSO{" "}
                                                    {`#${parseInt(
                                                        atob(
                                                            selectedRefund
                                                                ?.suspectedRefundFiles
                                                                ?.suspectRefundId
                                                        ).split(":")[1]
                                                    )}`}
                                                </TextRefundId>
                                            )}
                                        </SelectedRefundImage>
                                    )}
                                </ModalRefundImageHolder>
                                <ModalRefundImageActions>
                                    {impersonateCompanyId === null ? (
                                        selectedRefund.isNotRefundable ? (
                                            <>
                                                {selectedRefund.status === 2 && (
                                                    <Tooltip title="Aprovado" placement="top" arrow>
                                                        <img src="/img/btn_pay_paid.svg" alt="Aprovado" style={{ marginRight: 24 }} />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status < 4 && selectedRefund.status !== 2 && (
                                                    <Tooltip title="Aprovar" placement="top" arrow>
                                                        <ImageClickable
                                                            src="/img/btn_pay.svg"
                                                            alt="Aprovar"
                                                            style={{ marginRight: 24 }}
                                                            onClick={() => {
                                                                updateRefund(selectedRefund.id, 2, false, true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status < 4 && selectedRefund.status !== 3 && (
                                                    <Tooltip title="Rejeitar" placement="top" arrow>
                                                        <ImageClickable
                                                            src="/img/btn_reject.svg"
                                                            alt="Rejeitar"
                                                            style={{ marginRight: 24 }}
                                                            onClick={() => {
                                                                setOpenRejector(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status === 3 && (
                                                    <Tooltip title="Rejeitado" placement="top" arrow>
                                                        <img src="/img/btn_rejected.svg" alt="Rejeitado" style={{ marginRight: 24 }} />
                                                    </Tooltip>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {selectedRefund.status === 2 && (
                                                    <Tooltip title="Aprovado" placement="top" arrow>
                                                        <img src="/img/btn_pay_paid.svg" alt="Aprovado" style={{ marginRight: 24 }} />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status === 4 && (
                                                    <Tooltip placement="top" arrow>
                                                        <img src="/img/btn_pay_enabled.svg" alt="Aprovado" style={{ marginRight: 24 }} />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status < 4 && selectedRefund.status !== 2 && (
                                                    <Tooltip title="Aprovar" placement="top" arrow>
                                                        <ImageClickable
                                                            src="/img/btn_pay.svg"
                                                            alt="Aprovar"
                                                            style={{ marginRight: 24 }}
                                                            onClick={() => {
                                                                updateRefund(selectedRefund.id, 2, false, true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status === 3 && (
                                                    <Tooltip title="Rejeitado" placement="top" arrow>
                                                        <img src="/img/btn_rejected.svg" alt="Rejeitado" style={{ marginRight: 24 }} />
                                                    </Tooltip>
                                                )}
                                                {selectedRefund.status !== 3 && (
                                                    <Tooltip title="Rejeitar" placement="top" arrow>
                                                        <ImageClickable
                                                            src="/img/btn_reject.svg"
                                                            alt="Rejeitar"
                                                            style={{ marginRight: 24 }}
                                                            onClick={() => {
                                                                setOpenRejector(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                {canPay && (
                                                    <>
                                                        {selectedRefund.status === 4 && !selectedRefund.paidWithCredit && (
                                                            <Tooltip title="Pago" placement="top" arrow>
                                                                <img src="/img/btn_paid.svg" alt="Pago" style={{ marginRight: 24 }} />
                                                            </Tooltip>
                                                        )}
                                                        {selectedRefund.status < 4 && (
                                                            <Tooltip title="Pagar" placement="top" arrow>
                                                                <ImageClickable
                                                                    src="/img/btn_to_pay.svg"
                                                                    alt="Pagar"
                                                                    style={{
                                                                        marginRight: 24,
                                                                        cursor: selectedRefund.status === 2 ? "pointer" : "not-allowed",
                                                                        opacity: selectedRefund.status === 2 ? 1 : 0.5,
                                                                    }}
                                                                    onClick={
                                                                        selectedRefund.status === 2
                                                                            ? () => {
                                                                                updateRefund(selectedRefund.id, 4, false, true);
                                                                            }
                                                                            : null
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        {selectedRefund.status < 4 &&
                                                            parseFloat(selectedRefund.user.availableCredit) >= selectedRefund.value && (
                                                                <Tooltip title="Usar crédito" placement="top" arrow>
                                                                    <ImageClickable
                                                                        src="/img/btn_credit.svg"
                                                                        alt="Usar crédito"
                                                                        onClick={
                                                                            selectedRefund.status === 2
                                                                                ? () => {
                                                                                    updateRefund(selectedRefund.id, 4, true, true);
                                                                                }
                                                                                : null
                                                                        }
                                                                        style={{
                                                                            marginRight: 24,
                                                                            cursor: selectedRefund.status === 2 ? "pointer" : "not-allowed",
                                                                            opacity: selectedRefund.status === 2 ? 1 : 0.5,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        {selectedRefund.status < 4 &&
                                                            parseFloat(selectedRefund.user.availableCredit) < selectedRefund.value && (
                                                                <Tooltip title="Crédito insuficiente" placement="top" arrow>
                                                                    <img src="/img/btn_credit_disabled.svg" alt="Crédito insuficiente" style={{ marginRight: 24 }} />
                                                                </Tooltip>
                                                            )}
                                                        {selectedRefund.paidWithCredit && (
                                                            <Tooltip title="Pago com créditos" placement="top" arrow>
                                                                <img src="/img/btn_credit_enabled.svg" alt="Pago com crédito" style={{ marginRight: 24 }} />
                                                            </Tooltip>
                                                        )}
                                                        {selectedRefund.status === 4 && !selectedRefund.paidWithCredit && (
                                                            <Tooltip placement="top" arrow>
                                                                <img src="/img/btn_credit_disabled.svg" alt="Pago com crédito" style={{ marginRight: 24 }} />
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                )}
                                                {selectedRefund.status !== 4 && !selectedRefund.paidWithCredit && (
                                                    <Tooltip title="Retornar para pendente" placement="top" arrow>
                                                        <img
                                                            src="/img/btn_pending.svg"
                                                            alt="Retornar para pendente"
                                                            style={{ marginRight: 24, cursor: "pointer" }}
                                                            onClick={() => updateRefund(selectedRefund.id, 1, false, true)}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )
                                    ) : (
                                        <>
                                            {selectedRefund.status < 4 && (
                                                <Tooltip title="Pagar" placement="top" arrow>
                                                    <ImageClickable
                                                        src="/img/btn_paid.svg"
                                                        alt="Pagar"
                                                        style={{ cursor: "not-allowed", marginRight: 24 }}
                                                    />
                                                </Tooltip>
                                            )}
                                            {selectedRefund.status < 4 && selectedRefund.status !== 3 && (
                                                <Tooltip title="Rejeitar" placement="top" arrow>
                                                    <ImageClickable
                                                        src="/img/btn_rejected_disabled.svg"
                                                        alt="Rejeitado"
                                                        style={{ cursor: "not-allowed", marginRight: 24 }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </ModalRefundImageActions>
                            </ModalRefundImage>
                        </ModalRefundView>
                    </ModalCenter>
                </Modal>
            )}
            <Modal
                open={openIntegratedReport}
                onClose={handleIntegratedReportClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalRejector style={{ height: "auto" }}>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleIntegratedReportClose}
                    />
                    <ModalPadding>
                        <BasicTitle title={"Selecione o modelo"} />
                    </ModalPadding>
                    <FormControl
                        style={{ width: "100%", marginBottom: 24, padding: 16 }}
                    >
                        <Select
                            defaultValue={selectedIntegratedReport}
                            value={selectedIntegratedReport}
                            onChange={handleChangeSelectedIntegratedReport}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key={1} value={"1"}>
                                {"Resumido"}
                            </MenuItem>
                            <MenuItem key={2} value={"2"}>
                                {"Completo"}
                            </MenuItem>
                            <MenuItem key={3} value={"3"}>
                                {"Agrupado Centro de Custo e Categoria"}
                            </MenuItem>
                            <MenuItem key={4} value={"4"}>
                                {"Nibo"}
                            </MenuItem>
                            {allowedCompaniesSap.includes(companyId) && (
                                <MenuItem key={5} value={"5"}>
                                    {"SAP"}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{
                            width: "100%",
                            marginBottom: 24,
                            padding: 16,
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                         {selectedIntegratedReport === "5" ? (
                            <Button
                                style={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    padding: "12px 12px",
                                    margin: "0 auto",
                                }}
                                variant="contained"
                                disableelevation="true"
                                color="primary"
                                onClick={downloadCSVButton}
                                disabled={isCSVLoading || isXLSXLoading}
                            >
                                {isCSVLoading ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    "BAIXAR XLSX"
                                )}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 12,
                                        padding: "12px 12px",
                                        margin: "0 auto",
                                    }}
                                    variant="contained"
                                    disableelevation="true"
                                    color="primary"
                                    onClick={downloadCSVButton}
                                    disabled={isCSVLoading || isXLSXLoading}
                                >
                                    {isCSVLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        "BAIXAR CSV"
                                    )}
                                </Button>
                                <Button
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 12,
                                        padding: "12px 12px",
                                        margin: "0 auto",
                                    }}
                                    variant="contained"
                                    disableelevation="true"
                                    color="primary"
                                    onClick={downloadXLSXButton}
                                    disabled={isCSVLoading || isXLSXLoading}
                                >
                                    {isXLSXLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        "BAIXAR XLSX"
                                    )}
                                </Button>
                            </>
                        )}
                    </FormControl>
                </ModalRejector>
            </Modal>
            <Modal
                open={openModalMessageExport}
                onClose={closeModalMessageExport}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalRejector style={{ height: "auto" }}>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={closeModalMessageExport}
                    />
                    <ModalPadding>
                        <BasicTitle title={"EXPORTAR DADOS"} />
                    </ModalPadding>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <TextModalExport>
                            AGUARDE ENQUANTO PREPARAMOS
                            <br /> SEU ARQUIVO.
                        </TextModalExport>
                        <img
                            src="/img/man.png"
                            alt=""
                            width={240}
                            height={240}
                        />
                        <TextModalExport
                            style={{
                                fontSize: 14,
                                marginTop: 24,
                                marginBottom: 40,
                                fontWeight: 400,
                            }}
                        >
                            Esta operação poderá levar alguns
                            <br /> minutos. Você receberá um e-mail
                            <br /> quando terminarmos de preparar tudo.
                        </TextModalExport>
                        <Button
                            style={{ marginBottom: 20 }}
                            variant="contained"
                            disableelevation="true"
                            color="primary"
                            onClick={() => {
                                closeModalMessageExport();
                                handleIntegratedReportClose();
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </ModalRejector>
            </Modal>
            <Modal
                open={openRejector}
                onClose={handleRejectorClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalRejector>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleRejectorClose}
                    />
                    <ModalPadding>
                        <BasicTitle title={"REJEITAR DESPESA"} />
                    </ModalPadding>
                    <TextArea
                        placeholder="INSIRA AQUI O MOTIVO DA REJEIÇÃO DA DESPESA..."
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                    />
                    {errorMessage && (
                        <ErrorMessage>
                            Obrigatório preencher motivo de rejeição...
                        </ErrorMessage>
                    )}
                    <RejectButton
                        onClick={() => {
                            handleRejectButton(selectedRefund);
                            setRejectReason("");
                        }}
                    >
                        REJEITAR
                    </RejectButton>
                </ModalRejector>
            </Modal>
            <Modal
                open={openFilterDay}
                onClose={handleFilterDayClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalCenter
                    style={{
                        overflowY: "auto",
                        maxHeight: "95%",
                        width: 550,
                        height: "auto",
                        padding: 30,
                    }}
                >
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleFilterDayClose}
                        style={{ top: 30, right: 30 }}
                    />
                    <BasicTitle title={"Período"} />
                    <div
                        style={{
                            width: "100%",
                            marginBottom: 24,
                            marginTop: 24,
                            display: "flex",
                            justifyContent: "row",
                        }}
                    >
                        <FormControl style={{ marginRight: 24 }}>
                            <TextField
                                label="Data Inicial"
                                type="date"
                                style={{ width: 180 }}
                                value={`${startYear || ""}-${(
                                    startMonth || ""
                                ).padStart(2, "0")}-${(startDay || "").padStart(
                                    2,
                                    "0"
                                )}`}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <TextField
                                label="Data Final"
                                type="date"
                                style={{ width: 200 }}
                                value={`${endYear || ""}-${(
                                    endMonth || ""
                                ).padStart(2, "0")}-${(endDay || "").padStart(
                                    2,
                                    "0"
                                )}`}
                                onChange={handleEndDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyDown={handleKeyDown}
                            />
                        </FormControl>
                        <button
                            style={{ background: "none", border: "none" }}
                            onClick={() => {
                                handleConfirmFilterDate();
                            }}
                        >
                            <img
                                src="/img/confirm.svg"
                                alt=""
                                style={{
                                    width: 57,
                                    height: 57,
                                    cursor: "pointer",
                                    marginLeft: 16,
                                }}
                            />
                        </button>
                    </div>
                    {errorMessageDate && (
                        <ErrorMessage>{errorMessageDate}</ErrorMessage>
                    )}
                </ModalCenter>
            </Modal>
            <Modal
                open={openFilter}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") return; 
                    handleFilterClose();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalCenter style={{ overflowY: "auto", maxHeight: "95%" }}>
                    <ModalClose
                        src="/img/modal_close.svg"
                        onClick={handleFilterClose}
                    />
                    <BasicTitle title={"Gerenciar Filtros"} />
                    <FormControl
                        style={{
                            width: "100%",
                            marginBottom: 24,
                            marginTop: 24,
                        }}
                    >
                        <Autocomplete
                            multiple
                            options={usersActive}
                            value={selectedUsers}
                            getOptionLabel={(user) => user.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Usuários" />
                            )}
                            onChange={(event, newValue) => {
                                setSelectedUsers(newValue);
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            multiple
                            label="Status"
                            defaultValue={selectedStatus}
                            value={selectedStatus}
                            onChange={handleChangeStatus}
                            input={<OutlinedInput label="Chip" />}
                            renderValue={(selected) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                    }}
                                >
                                    {selected.map((value) => (
                                        <Chip
                                            key={value}
                                            label={getStatusName(value)}
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key={1} value={"1"}>
                                PENDENTE
                            </MenuItem>
                            <MenuItem key={2} value={"2"}>
                                APROVADO
                            </MenuItem>
                            <MenuItem key={3} value={"3"}>
                                REJEITADO
                            </MenuItem>
                            <MenuItem key={4} value={"4"}>
                                PAGO
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <Autocomplete
                            multiple
                            options={projects}
                            value={selectedProjects}
                            getOptionLabel={(project) => project.name}
                            filterOptions={(options, { inputValue }) => {
                                if (!inputValue) return options; 

                                const searchTerm = inputValue.toLowerCase().trim();

                                return options.filter((project) =>
                                    project.name.toLowerCase().includes(searchTerm)
                                );
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id} 
                            renderInput={(params) => (
                                <TextField {...params} label="Centro de Custo" />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                setSelectedProjects(newValue);
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <Autocomplete
                            multiple
                            options={categories}
                            value={selectedCategories}
                            getOptionLabel={(category) => category.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Categorias" />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setSelectedCategories(newValue);
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <InputLabel id="warning-select-label">
                            Cartão Corporativo
                        </InputLabel>
                        <Select
                            labelId="warning-select-label"
                            id="warning-select"
                            value={corporate}
                            onChange={handleChangeCorporate}
                            label="Cartão Corporativo"
                        >
                            <MenuItem value={null}>SEM FILTRO</MenuItem>
                            <MenuItem value={true}>
                                SOMENTE POR CARTÃO CORPORATIVO
                            </MenuItem>
                            <MenuItem value={false}>
                                EXCETO CARTÃO CORPORATIVO
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <InputLabel id="warning-select-label">
                            Recibos
                        </InputLabel>
                        <Select
                            labelId="warning-select-label"
                            id="warning-select"
                            value={receipt}
                            onChange={handleChangeReceipt}
                            label="Recibos"
                        >
                            <MenuItem value={null}>SEM FILTRO</MenuItem>
                            <MenuItem value={true}>
                                SOMENTE DESPESAS COM RECIBO
                            </MenuItem>
                            <MenuItem value={false}>
                                SOMENTE DESPESAS SEM RECIBO
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <InputLabel id="warning-select-label">
                            Alertas
                        </InputLabel>
                        <Select
                            labelId="warning-select-label"
                            id="warning-select"
                            value={warning}
                            onChange={handleChangeWarning}
                            label="Alerta"
                        >
                            <MenuItem value={null}>SEM FILTRO</MenuItem>
                            <MenuItem value={true}>
                                SOMENTE DESPESAS COM ALERTA
                            </MenuItem>
                            <MenuItem value={false}>
                                SOMENTE DESPESAS SEM ALERTA
                            </MenuItem>
                        </Select>
                    </FormControl>
                    {/* <FormControl style={{ width: "100%", marginBottom: 24 }}>
                        <InputLabel>Categorias</InputLabel>
                        <Select
                        multiple
                        label="Categorias"
                        defaultValue={selectedCategories}
                        value={selectedCategories}
                        onChange={handleChangeCategories}
                        input={<OutlinedInput label="Chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={getCategoryName(value)} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                            {category.name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl> */}
                    <Line />
                    <FormControl>
                        <Button
                            style={{
                                // width: "100%",
                                // maxWidth: 327,
                                fontWeight: 700,
                                fontSize: 12,
                                padding: "12px 12px",
                            }}
                            variant="contained"
                            disableelevation="true"
                            color="primary"
                            onClick={() => {
                                handleFilterClose();
                                setApplyFilters(true);
                                setFiltersApplied(true);
                            }}
                        >
                            APLICAR FILTROS
                        </Button>
                    </FormControl>
                    <FormControl style={{ marginLeft: 16 }}>
                        <Button
                            style={{
                                // width: "100%",
                                // maxWidth: 327,
                                fontWeight: 700,
                                fontSize: 12,
                                padding: "12px 12px",
                            }}
                            // variant="contained"
                            disableelevation="true"
                            color="error"
                            onClick={() => {
                                setSelectedCategories([]);
                                setSelectedProjects([]);
                                setSelectedStatus([]);
                                setSelectedUsers([]);
                                setCorporate(null);
                                setReceipt(null);
                                setWarning(null);
                                setStartMonth(null);
                                setStartYear(null);
                                setEndDay(null);
                                setEndMonth(null);
                                setEndYear(null);
                                setTimeout(() => {
                                    setApplyFilters(true);
                                    setFiltersApplied(false);
                                }, 300);
                            }}
                        >
                            LIMPAR SELEÇÃO
                        </Button>
                    </FormControl>
                </ModalCenter>
            </Modal>
            <Holder>
                <Content title={"Despesas"}>
                    <Filters
                        style={{ display: width < 1000 ? "block" : "flex" }}
                    >
                        <LeftFilters
                            style={{ marginBottom: width < 1000 ? 16 : 0 }}
                        >
                            <FormControl
                                style={{
                                    width: width < 1000 ? "100%" : 110,
                                    marginRight: 16,
                                }}
                                size="small"
                                disabled={startDay !== null}
                            >
                                <InputLabel>Ano</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={year}
                                    label="Ano"
                                    onChange={(event) => {
                                        setYear(event.target.value);
                                        setTimeout(() => {
                                            setApplyFilters(true);
                                        }, 300);
                                    }}
                                    disabled={pageState.isLoading}
                                >
                                    <MenuItem value={2025}>2025</MenuItem>
                                    <MenuItem value={2024}>2024</MenuItem>
                                    <MenuItem value={2023}>2023</MenuItem>
                                    <MenuItem value={2022}>2022</MenuItem>
                                    <MenuItem value={2021}>2021</MenuItem>
                                    <MenuItem value={2020}>2020</MenuItem>
                                    <MenuItem value={2019}>2019</MenuItem>
                                    <MenuItem value={2018}>2018</MenuItem>
                                    <MenuItem value={2017}>2017</MenuItem>
                                    <MenuItem value={2016}>2016</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                style={{ width: width < 1000 ? "100%" : 160 }}
                                size="small"
                                disabled={startDay !== null}
                            >
                                <InputLabel>Mês</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={month}
                                    label="Mês"
                                    onChange={(event) => {
                                        setMonth(event.target.value);
                                        setApplyFilters(true);
                                    }}
                                    disabled={pageState.isLoading}
                                >
                                    <MenuItem value={1}>Janeiro</MenuItem>
                                    <MenuItem value={2}>Fevereiro</MenuItem>
                                    <MenuItem value={3}>Março</MenuItem>
                                    <MenuItem value={4}>Abril</MenuItem>
                                    <MenuItem value={5}>Maio</MenuItem>
                                    <MenuItem value={6}>Junho</MenuItem>
                                    <MenuItem value={7}>Julho</MenuItem>
                                    <MenuItem value={8}>Agosto</MenuItem>
                                    <MenuItem value={9}>Setembro</MenuItem>
                                    <MenuItem value={10}>Outubro</MenuItem>
                                    <MenuItem value={11}>Novembro</MenuItem>
                                    <MenuItem value={12}>Dezembro</MenuItem>
                                </Select>
                            </FormControl>
                            <button
                                style={{
                                    marginLeft: 16,
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                                variant="contained"
                                disableelevation="true"
                                onClick={handleFilterDayOpen}
                                disabled={pageState.isLoading}
                            >
                                <img
                                    src="/img/calendar.svg"
                                    alt=""
                                    style={{
                                        width: 40,
                                        height: 40,
                                    }}
                                />
                            </button>
                            <Button
                                style={{
                                    marginLeft: 16,
                                    width: width < 1000 ? "100%" : 150,
                                    fontSize: 12,
                                    padding: "10px 0px",
                                }}
                                variant="contained"
                                disableelevation="true"
                                color="primary"
                                onClick={handleFilterOpen}
                                startIcon={
                                    <img src="/img/filtro.svg" alt="Filtro" />
                                }
                                disabled={pageState.isLoading}
                            >
                                FILTROS
                            </Button>
                        </LeftFilters>
                        <RightFilters>
                            {impersonateCompanyId === null ? (
                                <>
                                    {canPay && (
                                        <Button
                                            style={{
                                                marginLeft: width < 1000 ? 0 : 16,
                                                width: width < 1000 ? "100%" : 120,
                                                fontSize: 12,
                                                padding: "8px 0px",
                                                opacity:
                                                    selectedCells.length > 0 ? 1 : 0.4,
        
                                            }}
                                            variant="outlined"
                                            color={
                                                selectedCells.length > 0
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            disabled={selectedCells.length === 0}
                                            onClick={() => {
                                                updateRefunds(4);
                                            }}
                                        >
                                            PAGAR
                                        </Button>
                                    )}
                                    {canApprove && (
                                        <>
                                            <Button
                                                style={{
                                                    marginLeft: 16,
                                                    width: width < 1000 ? "100%" : 120,
                                                    fontSize: 12,
                                                    padding: "8px 0px",
                                                    opacity:
                                                        selectedCells.length > 0
                                                            ? 1
                                                            : 0.4,
                                                }}
                                                variant="outlined"
                                                color={
                                                    selectedCells.length > 0
                                                        ? "primary"
                                                        : "secondary"
                                                }
                                                disabled={selectedCells.length === 0}
                                                onClick={() => {
                                                    updateRefunds(2);
                                                }}
                                            >
                                                APROVAR
                                            </Button>
                                            <Button
                                                style={{
                                                    marginLeft: 16,
                                                    width: width < 1000 ? "100%" : 120,
                                                    fontSize: 12,
                                                    padding: "8px 0px",
                                                    opacity:
                                                        selectedCells.length > 0
                                                            ? 1
                                                            : 0.4,
                                                }}
                                                variant="outlined"
                                                color={
                                                    selectedCells.length > 0
                                                        ? "error"
                                                        : "secondary"
                                                }
                                                disabled={selectedCells.length === 0}
                                                onClick={() => {
                                                    setBatchReject(true);
                                                    setOpenRejector(true);
                                                }}
                                            >
                                                REJEITAR
                                            </Button>
                                        </>
                                    )}   
                                </> 
                            ) : (
                                <>
                                    <Button
                                        style={{
                                            marginLeft: 16,
                                            width: width < 1000 ? "100%" : 120,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        PAGAR
                                    </Button>
                                    <Button
                                        style={{
                                            marginLeft: 16,
                                            width: width < 1000 ? "100%" : 120,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        APROVAR
                                    </Button>
                                    <Button
                                        style={{
                                            marginLeft: 16,
                                            width: width < 1000 ? "100%" : 120,
                                            fontSize: 12,
                                            padding: "8px 0px",
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        REJEITAR
                                    </Button>
                                </>
                            )}
                            
                        </RightFilters>
                    </Filters>
                    {filtersApplied &&
                        (selectedUsers.length > 0 ||
                            selectedStatus.length > 0 ||
                            selectedCategories.length > 0 ||
                            selectedProjects.length > 0 ||
                            startDay !== null ||
                            corporate !== null ||
                            receipt !== null ||
                            warning !== null) && (
                            <>
                                <BasicTitle title={"Filtros Ativos"} />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ marginBottom: 3 }}
                                >
                                    {selectedUsers.map((user) => (
                                        <Chip
                                            label={user.name}
                                            onDelete={() => {
                                                const _n = selectedUsers.filter(
                                                    (e) => e.id !== user.id
                                                );
                                                setSelectedUsers(_n);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    ))}
                                    {selectedStatus.map((status) => (
                                        <Chip
                                            label={getStatusName(status)}
                                            onDelete={() => {
                                                const _n = selectedStatus.filter(
                                                    (e) => e !== status
                                                );
                                                setSelectedStatus(_n);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    ))}
                                    {selectedProjects.map((project) => (
                                        <Chip
                                            label={project.name}
                                            onDelete={() => {
                                                const _n = selectedProjects.filter(
                                                    (e) => e.id !== project.id
                                                );
                                                setSelectedProjects(_n);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    ))}
                                    {selectedCategories.map((category) => (
                                        <Chip
                                            label={category.name}
                                            onDelete={() => {
                                                const _n = selectedCategories.filter(
                                                    (e) => e.id !== category.id
                                                );
                                                setSelectedCategories(_n);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    ))}
                                    {startDay !== null && (
                                        <Chip
                                            label={`${startDay}/${startMonth}/${startYear} - ${
                                                endDay ? endDay : "--"
                                            }/${endMonth ? endMonth : "--"}/${
                                                endYear ? endYear : "----"
                                            }`}
                                            onDelete={() => {
                                                handleDeleteDate();
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    )}
                                    {corporate !== null && (
                                        <Chip
                                            label={
                                                corporate
                                                    ? "SOMENTE POR CARTÃO CORPORATIVO"
                                                    : "EXCETO CARTÃO CORPORATIVO"
                                            }
                                            onDelete={() => {
                                                setCorporate(null);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    )}
                                    {receipt !== null && (
                                        <Chip
                                            label={
                                                receipt ? "COM RECIBO" : "SEM RECIBO"
                                            }
                                            onDelete={() => {
                                                setReceipt(null);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    )}
                                    {warning !== null && (
                                        <Chip
                                            label={
                                                warning ? "COM ALERTA" : "SEM ALERTA"
                                            }
                                            onDelete={() => {
                                                setWarning(null);
                                                setTimeout(() => {
                                                    setApplyFilters(true);
                                                }, 300);
                                            }}
                                        />
                                    )}
                                    <Button
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 12,
                                            padding: "6px 12px",
                                        }}
                                        disableelevation="true"
                                        color="error"
                                        onClick={() => {
                                            setSelectedCategories([]);
                                            setSelectedProjects([]);
                                            setSelectedStatus([]);
                                            setSelectedUsers([]);
                                            setCorporate(null);
                                            setReceipt(null);
                                            setWarning(null);
                                            setStartDay(null);
                                            setStartMonth(null);
                                            setStartYear(null);
                                            setEndDay(null);
                                            setEndMonth(null);
                                            setEndYear(null);
                                            setTimeout(() => {
                                                setApplyFilters(true);
                                                setFiltersApplied(false);
                                            }, 300);
                                        }}
                                    >
                                        LIMPAR SELEÇÃO
                                    </Button>
                                </Stack>
                            </>
                        )}
                    <Summary
                        applyFilters={applyFilters}
                        month={month}
                        year={year}
                        startDay={startDay}
                        endDay={endDay}
                        startMonth={startMonth}
                        endMonth={endMonth}
                        startYear={startYear}
                        endYear={endYear}
                        selectedUsers={selectedUsers}
                        selectedStatus={selectedStatus}
                        selectedProjects={selectedProjects}
                        selectedCategories={selectedCategories}
                        corporate={corporate}
                        warning={warning}
                        refreshSummary={refreshSummary}
                    />
                    {loadingRefunds && !loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "500px",
                            }}
                        >
                            <CircularProgress size="40px" />
                        </div>
                    ) : pageState.data.length > 0 ? (
                        <>
                            <BasicTitle title={"Últimas Solicitações"} />
                            <Data style={{ marginBottom: 100 }}>
                                <DataGrid
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: pageState.pageSize,
                                            },
                                        },
                                    }}
                                    autoHeight
                                    rows={pageState.data}
                                    rowCount={pageState.total}
                                    loading={pageState.isLoading}
                                    rowsPerPageOptions={[25, 50]}
                                    pagination
                                    paginationModel={{
                                        page: pageState.page - 1,
                                        pageSize: pageState.pageSize,
                                    }}
                                    paginationMode="server"
                                    onPaginationModelChange={(model) => {
                                        const { page, pageSize } = model;
                                        setPageState((old) => ({
                                            ...old,
                                            page: page + 1,
                                            pageSize: pageSize,
                                        }));
                                    }}
                                    columns={columns}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    slots={{
                                        toolbar: toolbarDatagridOptions,
                                    }}
                                    onRowClick={handleRowClick}
                                    onRowSelectionModelChange={
                                        handleChangeCellSelection
                                    }
                                    componentsProps={{
                                        row: {
                                            style: { cursor: "pointer" },
                                        },
                                    }}
                                />
                            </Data>
                        </>
                    ) : (
                        <NoRefund>
                            {!loading ? (
                                <NoRefundImg src="/img/no_refunds.svg" />
                            ) : null}
                        </NoRefund>
                    )}
                </Content>
            </Holder>
        </>
    );
}
