import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Collapse, IconButton } from "@mui/material";
import BasicTitle from "../components/title";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";


import Api from "../libs/api";
import Storage from "../libs/storage";

import Loader from "./loader";

const ModalCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgb(144, 144, 144);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
`;

const ModalClose = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const ModalView = styled.div`
  margin-bottom: 16px;
`;

const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
`;

const Title = styled.h3`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
`;

const Clear = styled.div`
  clear: both;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #181122;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 5px;
`;

const ImageIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
  color: #57c4c2;
`;

const View = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  width: 25%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ErrorMessage = styled.p`
  color: red;
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 13px;
`;

const CheckBox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 15px;
`;

export default function AddProject({ open, close, profile, impersonate }) {
  let api = Api();
  let storage = Storage();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [name, setName] = useState("");
  const [company, setCompany] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [checkedState, setCheckedState] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);

  const getUsers = async (searchParam = null, endCursor = null , fromScroll = false) => {
    if (loading) return;

    setLoading(true);

    const data = await storage.getItem("data");
    const response = await api.getUsers(
        data.token,
        searchParam ? searchParam : null,
        endCursor,
        impersonate
    );

    let newUsers = response.data.allZagviewUsers.edges
      .map((item) => {
        return {
            id: item.node.id,
            name: item.node.name,
            restrictedProjects: item.node.hiddenProjects,
            disabled: item.node.disabled
        };
    });

    const currentUsersId = new Set();
    const currentUsers = [];

    if(fromScroll){
      users.forEach((user) => {
        currentUsersId.add(user.id);
        currentUsers.push(user);
      });
    }

    newUsers.forEach((user) => {
      if (!currentUsersId.has(user.id) && user.disabled === false) {
        currentUsers.push(user);
      }
    });

    setUsers(currentUsers);

    setEndCursor(response?.data?.allZagviewUsers?.pageInfo?.endCursor);
    setHasNextPage(response?.data?.allZagviewUsers?.pageInfo?.hasNextPage);

    setLoading(false);
  };


  useEffect(() => {
    if(open) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSearch = async () => {
    await setSearch(searchInput);
    await getUsers(searchInput, null, false);
  };

  const handleClearSearch = async () => {
    setSearch("");
    setSearchInput("");
    setUsers([]);
    setEndCursor(null);
    setHasNextPage(true);
    await getUsers();
  }

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !loading &&
        hasNextPage
    ) {
        getUsers(search, endCursor, true);
    }
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  useEffect(() => {
    setCheckedState(users.map((user) => selectAllChecked || selectedUsers.has(user.id)));
  }, [selectedUsers, users, selectAllChecked]);

  const handleCheckboxChange = (position) => {
    setSelectAllChecked(false);

    const checkedUser = users[position];

    const newselectedUsers = new Set(selectedUsers);

    if(selectedUsers.has(checkedUser.id)){
      newselectedUsers.delete(checkedUser.id);
    } else {
      newselectedUsers.add(checkedUser.id);
    }

    setSelectedUsers(newselectedUsers);
  };

  const selectedAll = () => {
    setSelectedUsers(new Set());
    setSelectAllChecked(!selectAllChecked);
  };

  const removeAllCheck = () => {
    setSelectedUsers(new Set());
    setSelectAllChecked(false);
  };

  useEffect(() => {
    if (profile) {
      setCompany(parseInt(atob(profile?.profile?.company?.id).split(":")[1]));
    }
  }, [profile]);

  const createProject = async () => {
    setLoading(true);
    const data = await storage.getItem("data");

    let _users = [...selectedUsers].map((userId) =>
      btoa(JSON.stringify({ id: userId, is_restricted: false }))
    );

    try {
      const response = await api.createProject(
        data.token,
        name,
        JSON.stringify(_users),
        company,
        selectAllChecked
      );

      if (response.data.createProjects.createProjectsError) {
        const errorMessage =
          response.data.createProjects.createProjectsError.messages[0];

        if (errorMessage === `Project ${name} cannot be used`) {
          setErrorMessage("Já existe um centro de custo com esse nome");
        } else {
          close();
          reset();
        }
      }
    } catch (error) {
      setErrorMessage("Ocorreu um erro inesperado");
    }
    setLoading(false);
    setSelectAllChecked(true);
  };

  const reset = () => {

    setName("");
    setSearch("");
    setUsers([]);
    setSelectedUsers(new Set());
    setSelectAllChecked(true);
    setErrorMessage(false);
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        open={open}
        onClose={() => {
          close();
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCenter
          style={{
            width: 1000,
            maxWidth: "60%",
            height: 1280,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          <ModalClose
            src="/img/modal_close.svg"
            onClick={() => {
              close();
              reset();
            }}
          />
          <Clear />
          <ModalView>
            <BasicTitle title={"ADICIONAR CENTRO DE CUSTO"} margin={32} />
            <div>
              <Button
                onClick={toggleDropdown1}
                variant="outlined"
                style={{
                  border: "none",
                  marginTop: 15,
                  padding: 0,
                }}
              >
                {isOpen1 ? (
                  <ImageIcon src="/img/arrow_up.svg" alt="" />
                ) : (
                  <ImageIcon src="/img/arrow_down.svg" alt="" />
                )}
                DETALHES
              </Button>
              <Box
                style={{
                  paddingBottom: 10,
                  border: "none",
                  borderBottom: "1px solid #E6E6E6",
                  width: "100%",
                  paddingTop: 20,
                  paddingLeft: 40,
                }}
              >
                <Collapse in={isOpen1}>
                  <FieldContainer>
                    <SubTitle>NOME</SubTitle>
                    <TextField
                      id="outlined-basic"
                      value={name}
                      variant="outlined"
                      style={{ borderRadius: 5, width: "30%" }}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FieldContainer>
                </Collapse>
              </Box>
              <Button
                onClick={toggleDropdown2}
                variant="outlined"
                style={{
                  border: "none",
                  marginTop: 15,
                  padding: 0,
                }}
              >
                {isOpen2 ? (
                  <ImageIcon src="/img/arrow_up.svg" alt="" />
                ) : (
                  <ImageIcon src="/img/arrow_down.svg" alt="" />
                )}
                USUÁRIOS
              </Button>
              <Box style={{ marginTop: 20, marginLeft: 40 }}>
                <Title>BUSCAR USUÁRIO</Title>
                <TextField
                  value={searchInput}
                  onChange={handleInputChange}
                  variant="outlined"
                  style={{ width: "282px", height: "35px", borderRadius: 8 }}
                  InputProps={{
                    endAdornment: searchInput ? (
                      <IconButton  onClick={handleClearSearch} edge="end">
                        <ClearIcon />
                      </IconButton>
                    ) : null,
                  }}
                />
                <Button
                  style={{ marginLeft: 16, fontSize: 12, padding: "10px", height: 57, borderRadius: 8 }}
                  variant="contained"
                  disableelevation="true"
                  color="primary"
                  onClick={handleSearch}
                >
                  <img src="/img/search.svg" alt="Filtro" style={{ width: 25, height: 25 }} />
                </Button>
              </Box>
              <Box
                style={{
                  paddingBottom: 10,
                  border: "none",
                  borderBottom: "1px solid #E6E6E6",
                  width: "100%",
                  paddingTop: 20,
                  paddingLeft: 40,
                }}
              >
                <Collapse in={isOpen2}>
                <View
                  id="scrollable-view"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                  onScroll={(e) => handleScroll(e)}
                >

                  {!search && (<View>
                    <CheckBox
                      style={{ marginBottom: 15 }}
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() => selectedAll()}
                    />
                    <Item style={{ margin: 0, fontWeight: 600 }}>SELECIONAR TUDO</Item>
                  </View>)}

                  {users.map((user, index) => (
                    <View key={user?.id} style={{ marginBottom: 15 }}>
                      <CheckBox
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        value={user.name}
                        checked={checkedState[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <Item style={{ margin: 0 }}>{user.name}</Item>
                    </View>
                  ))}
                  {loading && (
                    <View style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                      <CircularProgress size={24} />
                    </View>
                  )}
                </View>
                  <View>
                    <Button
                      style={{ color: "#DE6061", marginTop: 15 }}
                      onClick={() => {
                        removeAllCheck();
                      }}
                    >
                      REMOVER TODOS
                    </Button>
                  </View>
                </Collapse>
              </Box>
            </div>
          </ModalView>
          <ModalFooter>
            <div>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <Button
                onClick={() => {
                  createProject();
                }}
              >
                SALVAR
              </Button>
              <Button
                style={{ color: "#DE6061" }}
                onClick={() => {
                  close();
                  reset();
                }}
              >
                CANCELAR
              </Button>
            </div>
          </ModalFooter>
        </ModalCenter>
      </Modal>
    </>
  );
}
